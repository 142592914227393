<template>
<ul class="child-menu" v-if="(data && data.length)">
    <li
        class="child-menu__item"
        :class="{ 'is-opened': openedItems.includes(index) }"
        v-for="(item, index) in data"
        :key="index"
    >
        <span class="child-menu__link-wrapper">
            <a
                class="child-menu__link"
                :href="item.link"
                v-html="item.name"
            ></a>
            <z-icon
                name="round-arrow"
                dir="down"
                width="30"
                height="30"
                color="white"
                class="child-menu__icon"
                @click="toggleOpenedItem(index)"
            />
        </span>
        <transition
            @enter="animationEnter"
            @leave="animationLeave"
        >
            <ul
                class="child-menu__sub"
                v-if="((item.items && item.items.length) && openedItems.includes(index))"
            >
                <li
                    class="child-menu__sub-item"
                    v-for="(subItem, subIndex) in item.items"
                    :key="subIndex"
                >
                    <a
                        :href="subItem.link"
                        class="child-menu__sub-link"
                        :class="{ 'is-active': subItem.active }"
                    >
                        <z-icon
                            name="round-arrow-folder/arrow"
                            width="16"
                            height="16"
                            dir="right"
                            color="white"
                        />
                        <span v-html="subItem.name"></span>
                    </a>
                </li>
            </ul>
        </transition>
    </li>
</ul>
</template>

<script>
import Velocity from 'velocity-animate'

export default {
    name: 'child-menu',
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            openedItems: [],
            duration: 300
        }
    },
    methods: {
        checkOpened () {
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].active) {
                    this.openedItems.push(i)
                    break
                }
            }
        },
        toggleOpenedItem (item) {
            const index = this.openedItems.indexOf(item)

            if (index !== -1) {
                this.openedItems.splice(index, 1)
            } else {
                this.openedItems.push(item)
            }
        },
        animationEnter (el, done) {
            Velocity(el, 'slideDown', { duration: this.duration })
        },
        animationLeave (el, done) {
            Velocity(el, 'slideUp', { duration: this.duration })
        }
    },
    created () {
        this.checkOpened()
    }
}
</script>

<style lang="scss">
.child-menu {
    &__link {
        font-weight: 600;
        @include typo-level(M);
        color: $token-colors-white;
        cursor: pointer;
        text-decoration: none;
    }

    &__item {
        @include padding-level(top, XS);
        @include padding-level(bottom, XS);
        border-top: 1px solid rgba($token-colors-gray-20, 0.5);

        &:last-child {
            border-bottom: 1px solid rgba($token-colors-gray-20, 0.5);
        }

        &.is-opened {
            .child-menu__icon {
                transform: rotate(0deg);
            }
        }
    }

    &__link-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__sub {
        @include margin-level(top, 2XS);
    }

    &__icon {
        cursor: pointer;
        transition: transform $transition;
    }

    &__sub-item {
        & + & {
            @include margin-level(top, 2XS);
        }
    }

    &__sub-link {
        text-decoration: none;
        @include typo-level(S);
        color: $token-colors-white;
        display: flex;
        align-items: flex-start;
        line-height: 1.2;

        .z-icon {
            margin-top: 0.2em;
        }

        &.is-active {
            color: $token-colors-blue-40;
        }
    }
}
</style>
