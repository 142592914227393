<template>
<div class="calendar-filter">
    <filter-container :showResetBtn="isFilterActive" @clear="clear">
        <div class="calendar-filter__container">
            <template v-if="data.types && data.types.length">
                <z-caption
                    tag="h2"
                    decor-left
                    decor-right
                    size="m"
                    weight="700"
                    uppercase
                >
                    <span v-html="text.type"></span>
                </z-caption>
                <div class="calendar-filter__tags">
                    <tag
                        class="calendar-filter__tag"
                        v-for="(item, index) in data.types"
                        :key="index"
                        :theme="colorTheme[item.id] || 'blue'"
                        size="m"
                        :disabled="item.disabled"
                        :name="item.text"
                        :id="item.id"
                        decoration
                        @change="onTagChange"
                        ref="tag"
                    />
                    <z-button
                        class="u-top-margin--s"
                        kind="secondary"
                        size="s"
                        @click="clearTypes"
                        v-if="params.types.length"
                    >
                        <template #right>
                            <z-icon name="close" width="16" height="16" />
                        </template>
                        <span class="u-bold" v-html="text.clearTypes"></span>
                    </z-button>
                </div>
            </template>
            <template v-if="showPeriod">
                <datepicker-range
                    name="date"
                    @change="onDateChange"
                    ref="date"
                    :value="{
                        end: params.date[0],
                        start: params.date[1]
                    }"
                />
            </template>
        </div>
    </filter-container>
</div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'calendar-filter',
    props: {
        data: Object,
        showPeriod: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            text: {
                type: localize({
                    ru: 'Тип cобытия',
                    en: 'Event type'
                }),
                clearTypes: localize({
                    ru: 'Сбросить тип',
                    en: 'Reset type'
                })
            },
            colorTheme: {
                '73': 'event-green', // ir
                '74': 'event-purple', // bussiness
                '75': 'event-blue', // prod
                '77': 'event-red', // corp
                '76': 'event-orange', // fin
                '147': 'event-light-green' // esg
            },
            period: {
                start: null,
                end: null
            },
            params: {
                types: [],
                date: []
            }
        }
    },
    computed: {
        isFilterActive () {
            return Boolean(
                this.params.types.length ||
                (this.params.date.length && this.params.date[0] && this.params.date[1])
            )
        }
    },
    methods: {
        onTagChange (data) {
            let index = this.params.types.indexOf(data.id)
            index === -1 ? this.params.types.push(data.id) : this.params.types.splice(index, 1)
        },
        onDateChange (data) {
            this.params.date = [data.value.start, data.value.end]
        },
        clearTypes () {
            this.$refs.tag.forEach(item => {
                item.clear()
            })
            this.params.types = []
        },
        clear () {
            if (this.$refs.tag && this.$refs.tag.length) this.clearTypes()
            if (this.$refs.date) this.$refs.date.clear()
        }
    },
    watch: {
        params: {
            handler (value) {
                this.$emit('change', value)
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
.calendar-filter {
    &__tags {
        display: flex;
        flex-direction: column;
        margin-top: -$token-spacers-2-xs;
        align-items: flex-start;
    }

    &__tag {
        margin-top: $token-spacers-2-xs;
    }

    &__period {
        display: flex;
        align-items: center;
        position: relative;
        max-width: 500px;

        > span {
            width: 40px;
            position: relative;

            &:after {
                content: '';
                width: 16px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
                height: 1px;
                background: $token-colors-gray-35;
            }
        }

        .z-date-picker-popup {
            width: 100%;
            top: calc(100% + 16px) !important;
            left: 0 !important;
        }

        .mx-calendar {
            width: 100%;
        }
    }
}
</style>
