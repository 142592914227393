<template>
    <div class="header-menu">
        <ul class="header-menu__list">
            <li
                class="header-menu__list-item"
                :class="{ 'header-menu__list-item--opened': currentNav.value }">
                <div
                    class="header-menu__submenu-wrapper container"
                    v-if="
                        currentNav.value && currentNav.value.items && currentNav.value.items.length
                    ">
                    <div class="u-relative">
                        <button
                            type="button"
                            class="header-menu__button"
                            @click="navHandler($event, '', null)">
                            <z-icon
                                name="close"
                                width="32"
                                height="32"
                                class="header-menu__button-icon" />
                        </button>
                        <div class="header-menu__submenu-heading">
                            <z-caption
                                v-if="!isRedirect(currentNav.value)"
                                tag="a"
                                :href="getHref(currentNav.value)"
                                :decorLeft="true"
                                theme="white"
                                size="m"
                                uppercase
                                weight="700"
                                class="u-bottom-margin--xs-important"
                                @click.native="currentNav.value.params.reachGoal
                                    ? analyticsHandler($event, currentNav.value)
                                    : undefined"
                            >
                                {{ currentNav.value.name }}
                            </z-caption>
                        </div>
                        <div class="header-menu__row">
                            <div class="header-menu__col header-menu__col--1">
                                <ul class="header-menu-item">
                                    <template v-for="(itemB, indexB) in currentNav.value.items">
                                        <li
                                            class="header-menu-item__list-item"
                                            :class="[
                                                {
                                                    'header-menu-item__list-item--opened':
                                                        `item-${indexB}` === id,
                                                    'header-menu-item__list-item--active':
                                                        itemB.active,
                                                    'header-menu-item__list-item--highlighted':
                                                        itemB.params.highlighted
                                                }
                                            ]"
                                            :key="indexB">
                                            <a
                                                :href="getHref(itemB)"
                                                @click="
                                                    showNextLvl($event, itemB, `item-${indexB}`)
                                                "
                                                class="header-menu-item__item-link">
                                                <span
                                                    class="header-menu-item__item-link-name"
                                                    v-html="itemB.name" />
                                                <span
                                                    v-if="itemB.parent"
                                                    class="header-menu-item__item-link-arrow">
                                                    <z-icon
                                                        name="round-arrow-folder/arrow"
                                                        width="16"
                                                        height="16"
                                                        dir="right"
                                                        color="#fff" />
                                                </span>
                                            </a>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                            <div
                                class="header-menu__col header-menu__col--2"
                                :style="{ opacity: nextLvl ? '1' : '0' }">
                                <template v-if="nextLvl">
                                    <div
                                        class="header-menu__submenu-heading"
                                        :class="{ 'is-active': nextLvl.active }">
                                        <z-caption
                                            :tag="isRedirect(nextLvl) ? 'span' : 'a'"
                                            :href="isRedirect(nextLvl) ? false : getHref(nextLvl)"
                                            theme="white"
                                            size="l"
                                            class="u-bottom-margin--xs-important">
                                            {{ nextLvl.name }}
                                        </z-caption>
                                    </div>
                                    <ul class="header-submenu">
                                        <li
                                            class="header-submenu__item"
                                            :class="{
                                                'header-submenu__item--active': itemC.active,
                                                'header-submenu__item--highlighted':
                                                    itemC.params.highlighted
                                            }"
                                            v-for="(itemC, indexC) in nextLvl.items"
                                            :key="indexC">
                                            <a
                                                :href="getHref(itemC)"
                                                @click="showNextLvl($event, itemC, '3')"
                                                class="header-submenu__item-link">
                                                <span
                                                    class="header-submenu__link-text"
                                                    v-html="itemC.name" />
                                            </a>
                                        </li>
                                    </ul>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import handleYmGoal from '../utils/analytics.js'

export default {
    name: 'HeaderNavigationPopup',
    inject: ['currentNav', 'navHandler', 'getHref', 'isRedirect', 'isBlank'],
    data () {
        return {
            nextLvl: null,
            id: ''
        }
    },
    watch: {
        'currentNav.value'() {
            this.nextLvl = null
            this.id = ''
        }
    },
    methods: {
        showNextLvl (event, item, id = '') {
            if (item.parent) {
                event.preventDefault()
                this.nextLvl = item
                this.id = id
            }
        },
        analyticsHandler (e, item) {
            if (window.location.pathname === '/') {
                e.preventDefault()
                const lang = this.$root.lang
                handleYmGoal(item.params.reachGoal, lang)
                window.open(item.link, '_self')
            }
        }
    }
}
</script>

<style lang="scss">
$transition: 0.25s ease-in-out;

.header-menu {
    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__submenu-wrapper {
        position: relative;
        padding-bottom: 32px;

        @include breakpoint(tablet) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @include breakpoint(mobile) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    &__row {
        margin-left: -32px;
        margin-right: -32px;
        width: auto;
        display: flex;
    }

    &__col {
        width: 30%;
        padding-left: 32px;
        padding-right: 32px;

        @media (max-width: 1600px) {
            width: 40%;
        }

        &--2 {
            border-left: 1px solid #004081;
            border-radius: 0 4px 4px 0;
            max-height: 445px;
            overflow: auto;
            height: auto;
            align-self: flex-start;
            border-left-width: 2px;

            &::-webkit-scrollbar {
                width: 5px;
                border-radius: 36px;
                background: #add1ea;
            }

            &::-webkit-scrollbar-thumb {
                background: #0077c8;
                border-radius: 36px;
            }
        }
    }

    &__list-item {
        position: absolute;
        left: 0;
        right: 0;
        background-color: $token-colors-navy-blue;
        top: 100%;
        z-index: 100;
        display: none;
        margin: 0 auto;
        border-radius: 0 0 100px 0;
        padding-top: 32px;

        &--opened {
            display: block;
        }
    }

    &__submenu-heading {
        display: flex;
        justify-content: space-between;
    }

    &__button {
        width: 32px;
        height: 32px;
        background-color: transparent;
        border: none;
        outline: none;
        margin-left: auto;
        position: absolute;
        right: 0;
        top: 0;

        &-icon {
            path {
                fill: white;
            }

            &:hover {
                path {
                    fill: #80bbe3;
                }
            }
        }
    }
}

.header-menu-item {
    $item: &;
    list-style: none;
    margin: 0;

    &__item-link {
        text-decoration: none;
        color: #eff6fb;
        font-size: 18px;
        line-height: 1;
        padding: 4px 8px 6px;
        display: inline-block;

        span {
            line-height: 1;
            display: inline-block;
        }

        @media (max-width: 1149px) {
            font-size: 20px;
        }

        &:hover {
            color: #25a7ff;

            path {
                fill: #25a7ff;
                transition: fill $transition;
            }
        }
    }

    &__list-item {
        margin-bottom: 6px;

        &:last-child {
            margin-bottom: 0;
        }

        &--opened {
            #{$item}__item-link {
                background-color: #004081;
                border-radius: 4px;
                color: #25a7ff;

                path {
                    fill: #25a7ff;
                }
            }
        }

        &--highlighted {
            #{$item}__item-link {
                transition: background-color $transition, color $transition, opacity $transition;
                background-color: $token-colors-sulfur;
                color: $token-colors-navy-blue;
                border-radius: 38px;
                padding-left: 16px;
                padding-right: 16px;

                path {
                    fill: $token-colors-navy-blue;
                }
            }

            &:hover {
                #{$item}__item-link {
                    background-color: #8ab009;
                    color: $token-colors-navy-blue;
                }
            }

            &.header-menu-item__list-item--active,
            &.header-menu-item__list-item--opened {
                #{$item}__item-link {
                    color: #002d59;

                    path {
                        fill: #002d59;
                    }
                }
            }
        }

        &--active {
            #{$item}__item-link {
                color: #82ccff;

                path {
                    fill: #82ccff;
                }
            }
        }
    }
}

.header-submenu {
    list-style: none;
    margin: 0;
    padding: 0;
    $submenu: &;

    &__item {
        margin-top: 0;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        @media (max-width: 1149px) {
            margin-top: 10px;
        }

        &--active {
            pointer-events: none;

            #{$submenu}__item-link {
                color: #82ccff;
            }
        }

        &--highlighted {
            #{$submenu}__item-link {
                transition: background-color $transition, color $transition, opacity $transition;
                background-color: $token-colors-sulfur;
                color: $token-colors-navy-blue;
                border-radius: 38px;
                padding-left: 12px;
                padding-right: 12px;
                width: fit-content;
            }

            &:hover {
                #{$submenu}__item-link {
                    background-color: #8ab009;
                    color: $token-colors-navy-blue;
                }
            }

            &.header-submenu__item--active,
            &.header-submenu__item--opened {
                #{$submenu}__item-link {
                    color: #002d59;
                }
            }
        }
    }

    &__item-link {
        text-decoration: none;
        color: #f5f5f5;
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 1.4;

        &:hover {
            color: #25a7ff;

            path {
                fill: #25a7ff;
            }
        }
    }

    &__link-img {
        height: 16px;
        width: 16px;
        margin-right: 12px;
        display: flex;
        align-items: center;
    }
}
</style>
