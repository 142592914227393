<template>
    <div class="header-navigation-main">
        <nav :class="['header-navigation-main__nav', { 'is-opened': currentNav.value }]">
            <ul class="header-navigation-main__list">
                <li
                    :class="[
                        'header-navigation-main__list-item',
                        {
                            'header-navigation-main__list-item--opened':
                                `main-link-${indexA}` === currentNavId.value,
                            'is-active': itemA.active,
                            'header-navigation-main__list-item--highlighted':
                                itemA.params.highlighted
                        }
                    ]"
                    v-for="(itemA, indexA) in data"
                    :key="indexA"
                    @click="navHandler($event, `main-link-${indexA}`, itemA)">
                    <a
                        :href="getHref(itemA)"
                        class="header-navigation-main__item-link"
                        :class="{
                            'header-navigation-main__item-link--is-parent':
                                itemA.items && itemA.items.length
                        }"
                        :target="isBlank(itemA)">
                        <span
                            class="header-navigation-main__link-name"
                            v-html="itemA.name" />
                        <span class="header-navigation-main__link-arrow">
                            <z-icon
                                name="round-arrow-folder/arrow"
                                width="16"
                                height="16"
                                dir="down"
                                color="#fff" />
                        </span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'header-navigation-main',
    inject: ['currentNav', 'currentNavId', 'navHandler', 'getHref', 'isBlank'],
    props: {
        columnsCount: {
            type: Number,
            default: 3
        }
    },
    data() {
        return {
            data: this.$root.app.components.navigation.top
        }
    }
}
</script>

<style lang="scss">
.header-navigation-main {
    $parent: &;
    $transition: 0.25s ease-in-out;

    @media (max-width: 1148px) {
        display: none;
    }

    &__nav {
        position: relative;

        &:after {
            content: '';
            width: 100%;
            height: 1px;
            background: $token-colors-blue-30;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            opacity: 0;
            transition: opacity $transition;
        }

        &.is-opened {
            &:after {
                opacity: 1;
            }
        }
    }

    &__list {
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        margin: 0;
    }

    &__list-item {
        padding: 10px 10px 0;
        padding-bottom: 32px;
        cursor: pointer;
        position: relative;
        transition: color $transition;

        &::before {
            content: '';
            height: 8px;
            width: 100%;
            bottom: -4px;
            border-radius: 50px;
            position: absolute;
            left: 0;
            background-color: currentColor;
            opacity: 0;
            transition: opacity $transition, background-color $transition;
        }

        &:hover {
            color: $token-colors-button-bordered-light-default;

            #{$parent}__item-link {
                color: $token-colors-button-bordered-light-default;
            }

            &::before {
                opacity: 1;
            }
        }

        &:active {
            opacity: 0.8;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &.is-active {
            color: $token-colors-link-light-active;

            #{$parent}__item-link,
            .z-caption {
                color: $token-colors-link-light-active;

                path {
                    fill: $token-colors-link-light-active;
                }
            }
        }

        &--opened {
            color: $token-colors-blue-30;
            #{$parent}__item-link {
                color: $token-colors-blue-30;
            }

            &::before {
                opacity: 1;
            }

            #{$parent}__link-arrow {
                transform: rotate(180deg);
            }
        }

        &--highlighted {
            color: $token-colors-sulfur;

            #{$parent}__item-link {
                transition: background-color $transition, color $transition, opacity $transition;
                background-color: $token-colors-sulfur;
                color: $token-colors-navy-blue;
                padding-bottom: 0;
                padding-left: 16px;
                padding-right: 16px;
                border-radius: 38px;
            }

            &:hover {
                color: #8ab009;

                #{$parent}__item-link {
                    color: $token-colors-navy-blue;
                    background-color: #8ab009;
                }
            }

            &.is-active,
            &.header-navigation-main__list-item--opened {
                color: $token-colors-sulfur;

                &::before {
                    opacity: 1;
                }

                #{$parent}__item-link {
                    color: #002d59;

                    path {
                        fill: #002d59;
                    }
                }
            }
        }
    }

    &__item-link {
        text-decoration: none;
        font-size: 18px;
        color: #f5f5f5;
        position: relative;
        letter-spacing: 0.3px;
        display: flex;
        align-items: center;
        overflow: hidden;
        transition: color $transition;

        &--is-parent {
            #{$parent}__link-name {
                margin-right: 8px;
            }

            #{$parent}__link-arrow {
                display: flex;
            }
        }

        @include breakpoint(tablet) {
            font-size: 14px;
        }
    }

    &__link-arrow {
        display: none;
        align-items: center;
        transition: transform 300ms ease;

        path {
            fill: currentColor;
            transition: fill $transition;
        }
    }

    &__link-img {
        color: red;
    }
}
</style>
