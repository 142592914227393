<template>
    <section class="logistic-sales u-top-margin--3xl">
        <div class="container">
            <div class="logistic-sales__content">
                <z-caption
                    :decor-right="true"
                    tag="h3"
                    size="3xl"
                    class="u-bottom-margin--s-important"
                >
                    <span data-scroll-heading>{{ text.title }}</span>
                </z-caption>
                <div class="logistic-sales__inner">
                    <z-caption
                        :decor-left="true"
                        :decor-right="true"
                        tag="p"
                        size="m"
                        weight="700"
                        class="u-bottom-margin--xl-important"
                    >
                        <span data-scroll-heading>{{ text.complex }}</span>
                    </z-caption>
                    <div class="logistic-sales__grid">
                        <div class="logistic-sales__col" data-scroll-translate>
                            <p class="logistic-sales__col-title u-bottom-margin--s">{{ text.navy.title }}</p>
                            <z-list>
                                <z-list-item>{{ text.navy.label }}</z-list-item>
                                <z-list-item>{{ text.navy.secLabel }}</z-list-item>
                            </z-list>
                        </div>
                        <div class="logistic-sales__col" data-scroll-translate>
                            <p class="logistic-sales__col-title u-bottom-margin--s">{{ text.riverFleet.title }}</p>
                            <z-list>
                                <z-list-item>{{ text.riverFleet.label }}</z-list-item>
                                <z-list-item>{{ text.riverFleet.secLabel }}</z-list-item>
                            </z-list>
                        </div>
                        <div class="logistic-sales__col" data-scroll-translate>
                            <p class="logistic-sales__col-title u-bottom-margin--s">{{ text.railwayPark.title }}</p>
                            <z-list>
                                <z-list-item>{{ text.railwayPark.label }}</z-list-item>
                                <z-list-item>{{ text.railwayPark.secLabel }}</z-list-item>
                                <z-list-item>{{ text.railwayPark.thirdLabel }}</z-list-item>
                            </z-list>
                        </div>
                        <div class="logistic-sales__col" data-scroll-translate>
                            <p class="logistic-sales__col-title u-bottom-margin--s">{{ text.aviationAssets.title }}</p>
                            <z-list>
                                <z-list-item>{{ text.aviationAssets.label }}</z-list-item>
                                <z-list-item>{{ text.aviationAssets.secLabel }}</z-list-item>
                                <z-list-item>{{ text.aviationAssets.thirdLabel }}</z-list-item>
                            </z-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="logistic-sales__image">
            <z-image
                :src="lang === 'ru' ? '/local/templates/z-template/img/business-model/logistics.webp' : '/local/templates/z-template/img/business-model/logistics-en.webp'" width="100%"
            />
        </div>
    </section>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    data () {
        return {
            lang: this.$root.lang,
            text: {
                title: localize({
                    ru: '05. Логистика и сбыт продукции',
                    en: '05. Logistics and distribution'
                }),
                complex: localize({
                    ru: 'Транспортно-логистический комплекс Компании включает:',
                    en: 'Asset summary:'
                }),
                navy: {
                    title: localize({
                        ru: 'Морской флот',
                        en: 'Sea fleet'
                    }),
                    label: localize({
                        ru: 'Шесть судов усиленного ледового класса',
                        en: 'six heavy ice-class vessels'
                    }),
                    secLabel: localize({
                        ru: 'Портовый дизельный ледокол морского класса',
                        en: 'a sea-class diesel port icebreaker'
                    })
                },
                riverFleet: {
                    title: localize({
                        ru: 'Речной флот',
                        en: 'River fleet'
                    }),
                    label: localize({
                        ru: '622 ед. (из них 188 самоходных судов и 434 несамоходных), в том числе активное ядро флота — 406 ед.',
                        en: '622 vessels (188 self-propelled and 434 towed vessels), including the active core fleet of 406 vessels'
                    }),
                    secLabel: localize({
                        ru: 'Портовый дизельный ледокол речного класса',
                        en: 'a river-class diesel port icebreaker'
                    })
                },
                railwayPark: {
                    title: localize({
                        ru: 'Железнодорожный парк',
                        en: 'Rolling Stock Fleet'
                    }),
                    label: localize({
                        ru: '142 фитинговые платформы',
                        en: '142 container flatcars'
                    }),
                    secLabel: localize({
                        ru: 'Два маневровых локомобиля',
                        en: 'two shunting vehicles'
                    }),
                    thirdLabel: localize({
                        ru: 'Один маневровый локотрактор',
                        en: 'one shunting tractor'
                    })
                },
                aviationAssets: {
                    title: localize({
                        ru: 'Авиаактивы',
                        en: 'Aircraft fleet'
                    }),
                    label: localize({
                        ru: '17 вертолетов',
                        en: '17 helicopters'
                    }),
                    secLabel: localize({
                        ru: 'Один самолет',
                        en: 'one plane'
                    }),
                    thirdLabel: localize({
                        ru: 'Аэропорт «Норильск»',
                        en: 'Norilsk Airport'
                    })
                }
            }
        }
    }
}
</script>

<style lang="scss">
.logistic-sales {
    position: relative;
    @include padding-level(top, XL);
    background-color: #f2f6fb;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        height: 100%;
        background: #EDF5FC;
        transform: translate(-50% ,0);
        z-index: -1;
    }

    &__image{
        max-width: 1920px;
        margin: auto;
        
        @include breakpoint(v-tablet) {
            overflow-x: scroll;

            & .z-image {
                min-width: 1024px;
            }
        }
    }

    &__content {
        position: relative;
        z-index: 1;
    }

    &__inner {
        @include padding-level(top, L);
        @include padding-level(right, M);
        @include padding-level(bottom, M);
        @include padding-level(left, M);
        background: #ffffff;
        border-radius: 0 20px 20px 20px;

        & .z-caption--decor-left.z-caption--decor-right .z-caption__text {
            @include breakpoint(mobile) {
                padding-right: 0;
                max-width: 100%;
            }
        }
    }

    &__grid {
        display: flex;
        gap: 64px;

        @include breakpoint(laptop) {
            flex-wrap: wrap;
        }
    }

    &__col {
        min-width: 272px;
        max-width: 457px;

        @include breakpoint(laptop) {
            width: 100%;
            max-width: calc(50% - 32px);
        }

        @include breakpoint(v-tablet) {
            max-width: 100%;
        }
    }

    &__col-title {
        @include typo-level(XL);
        color: $token-colors-navy-blue;
    }
}
</style>
