var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-navigation-main"},[_c('nav',{class:['header-navigation-main__nav', { 'is-opened': _vm.currentNav.value }]},[_c('ul',{staticClass:"header-navigation-main__list"},_vm._l((_vm.data),function(itemA,indexA){return _c('li',{key:indexA,class:[
                    'header-navigation-main__list-item',
                    {
                        'header-navigation-main__list-item--opened':
                            ("main-link-" + indexA) === _vm.currentNavId.value,
                        'is-active': itemA.active,
                        'header-navigation-main__list-item--highlighted':
                            itemA.params.highlighted
                    }
                ],on:{"click":function($event){return _vm.navHandler($event, ("main-link-" + indexA), itemA)}}},[_c('a',{staticClass:"header-navigation-main__item-link",class:{
                        'header-navigation-main__item-link--is-parent':
                            itemA.items && itemA.items.length
                    },attrs:{"href":_vm.getHref(itemA),"target":_vm.isBlank(itemA)}},[_c('span',{staticClass:"header-navigation-main__link-name",domProps:{"innerHTML":_vm._s(itemA.name)}}),_vm._v(" "),_c('span',{staticClass:"header-navigation-main__link-arrow"},[_c('z-icon',{attrs:{"name":"round-arrow-folder/arrow","width":"16","height":"16","dir":"down","color":"#fff"}})],1)])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }