<template>
  <div
    v-if="data"
    class="files-accordion"
  >
    <z-accordion
      v-if="data.length"
      multiple
      size="m"
    >
      <z-accordion-item
        v-for="(accordion, index) in data"
        :id="accordion.name"
        :key="index"
      >
        <template #header>
          <span v-html="accordion.name" />
        </template>
        <template #body>
          <z-filelist>
            <div class="row">
              <div
                v-for="(item, index) in accordion.items"
                :key="index"
                class="col-default-12"
              >
                <z-filelist-item
                  :href="item.file.link"
                  :name="item.title"
                  :icon-name="item.file.type === `vnd.ms-excel` ? `xls` : 'file/' + item.file.type"
                  :file-size="item.file.size"
                >
                </z-filelist-item>
              </div>
            </div>
          </z-filelist>
        </template>
      </z-accordion-item>
    </z-accordion>
  </div>
</template>

<script setup>
export default {
    props: {
        data: {
            type: Array,
            required: true
        }
    }
}
</script>
