/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/map-map': {
    width: 91,
    height: 76,
    viewBox: '0 0 91 76',
    data: '<path pid="0" d="M31.938 40.214l-15.476 2.361L1.51 74.315l23.083-5.246m7.345-28.855L24.593 69.07m7.345-28.855l14.69 2.361M24.593 69.07l22.035 5.246m0 0v-31.74m0 31.74l19.411-5.246M46.628 42.575l15.739-2.36m3.672 28.854l-3.672-28.855m3.672 28.855l23.608 5.246-12.066-31.74-15.214-2.36" _stroke="url(#paint0_linear_10351_2023)" stroke-width="1.693"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M47.005 50.889L61.91 35.984a21.08 21.08 0 10-29.81 0l14.905 14.905z" _fill="#0077C8"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M32.1 35.985a21.08 21.08 0 1129.81 0L47.005 50.89 32.1 35.985zM47.005 48.49l13.706-13.706a19.383 19.383 0 10-27.412 0l13.706 13.706z" _fill="#fff"/><circle pid="3" cx="47.005" cy="21.194" r="8.273" _stroke="#fff" stroke-width="1.5"/><defs><linearGradient id="paint0_linear_10351_2023" x1="45.578" y1="40.214" x2="45.578" y2="74.315" gradientUnits="userSpaceOnUse"><stop stop-color="#fff" stop-opacity=".1"/><stop offset="1" stop-color="#fff"/></linearGradient></defs>'
  }
})
