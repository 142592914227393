<template>
    <section class="esg">
        <div class="right-col">
            <z-caption
                :decor-right="true"
                tag="h3"
                size="3xl"
                class="u-bottom-margin--2xl-important"
            >
               <span data-scroll-heading>{{ text.financialResults }}</span>
            </z-caption>
            <div class="esg__financial-results">
                <div class="esg__ecology">
                    <z-caption
                        :decor-left="true"
                        tag="p"
                        size="m"
                        weight="700"
                        class="u-bottom-margin--s-important"
                    >
                        <span data-scroll-heading>{{ text.ecology }}</span>
                    </z-caption>
                    <z-key-indicator
                        :value="lang === 'ru' ? '8,6' : '8.6'"
                        :value-text="text.mt"
                        :caption="text.greenhouseGas"
                        class="u-bottom-margin--m"
                        data-scroll-translate
                    />
                    <z-key-indicator
                        :value="lang === 'ru' ? '6,4' : '6.4'"
                        :value-text="text.mt"
                        :caption="text.greenhouseGasThree"
                        class="u-bottom-margin--m"
                        data-scroll-translate
                    />
                    <z-key-indicator
                        value="99"
                        value-text="%"
                        :caption="text.productionWaste"
                        class="u-bottom-margin--m"
                        data-scroll-translate
                    />
                    <z-key-indicator
                        value="55"
                        value-text="%"
                        :caption="text.shareOfElectricity"
                        class="u-bottom-margin--m"
                        data-scroll-translate
                    />
                    <z-key-indicator
                        value="83"
                        value-text="%"
                        :caption="text.shareOfReused"
                        data-scroll-translate
                    />
                </div>
                <div class="esg__results">
                    <z-caption
                        :decor-left="true"
                        tag="p"
                        size="m"
                        weight="700"
                        class="u-bottom-margin--s-important"
                    >
                        <span data-scroll-heading>{{ text.results }}</span>
                    </z-caption>
                    <z-key-indicator
                        :text-left="lang === 'en' ? 'USD' : ''"
                        :value="lang === 'ru' ? '6,9' : '6.9'"
                        :value-text="text.usdBn"
                        caption="EBITDA"
                        class="u-bottom-margin--m"
                        data-scroll-translate
                    />
                    <z-key-indicator
                        :text-left="lang === 'en' ? 'USD' : ''"
                        :value="lang === 'ru' ? '2,9' : '2.9'"
                        :value-text="text.usdBn"
                        :caption="text.netProfit"
                        class="u-bottom-margin--m"
                        data-scroll-translate
                    />
                    <z-key-indicator
                        value="48"
                        value-text="%"
                        :caption="text.profitability"
                        class="u-bottom-margin--m"
                        data-scroll-translate
                    />
                    <z-key-indicator
                        text-left="x"
                        :value="lang === 'ru' ? '1,2' : '1.2'"
                        :caption="text.netDebt"
                        data-scroll-translate
                    />

                    <z-card theme="grey" class="u-top-margin--3xl" data-scroll-translate>
                        <template #content>
                            <z-caption
                                :decor-left="true"
                                tag="p"
                                size="m"
                                weight="700"
                                class="u-bottom-margin--s-important"
                            >
                                {{ text.revenueStructure }}
                            </z-caption>
                            <z-chart v-if="lang === 'ru'"
                                :theme="[
                                    'gauge-title-center',
                                    'disable-legend-interaction'
                                ]"
                                :extended-options="{}"
                                :options='{
                                    size: {
                                        height: 375
                                    },
                                    color: {
                                        pattern: [
                                            "#1A1B25",
                                            "#C1C6D2",
                                            "#0077C8",
                                            "#ADD1EA",
                                        ]
                                    },
                                    padding: {
                                        top: 24,
                                        right: 24,
                                        bottom: 24,
                                        left: 24
                                    },
                                    donut: {
                                        width: 24,
                                        fullCircle: true,
                                        title: "14,4 \n млрд $ \n выручка",
                                        label: {
                                          ratio: 1.25,
                                          format: function (value, ratio, id) {
                                              return value;
                                          }
                                        },
                                        startingAngle: 1.2
                                    },
                                    data: {
                                        columns: [
                                            ["Азия", 54],
                                            ["Европа", 24],
                                            ["Россия и СНГ", 12],
                                            ["Северная и Южная Америка", 10],
                                        ],
                                        type: "donut"
                                    },
                                }'
                                :legend='{
                                    position: "left-bottom"
                                }'
                                legend-direction="col"
                            ></z-chart>
                            <z-chart v-else
                                :theme="[
                                    'gauge-title-center',
                                    'disable-legend-interaction'
                                ]"
                                :extended-options="{}"
                                :options='{
                                    size: {
                                        height: 375
                                    },
                                    color: {
                                        pattern: [
                                            "#1A1B25",
                                            "#C1C6D2",
                                            "#0077C8",
                                            "#ADD1EA",
                                        ]
                                    },
                                    padding: {
                                        top: 24,
                                        right: 24,
                                        bottom: 24,
                                        left: 24
                                    },
                                    donut: {
                                        width: 24,
                                        fullCircle: true,
                                        title: "USD \n 14.4 bn \n Revenue",
                                        label: {
                                          ratio: 1.25,
                                          format: function (value, ratio, id) {
                                              return value;
                                          }
                                        },
                                        startingAngle: 1.2
                                    },
                                    data: {
                                        columns: [
                                            ["Asia", 54],
                                            ["Europe", 24],
                                            ["Russia and the CIS", 12],
                                            ["Americas", 10],
                                        ],
                                        type: "donut"
                                    },
                                }'
                                :legend='{
                                    position: "left-bottom"
                                }'
                                legend-direction="col"
                            ></z-chart>
                        </template>
                    </z-card>
                </div>

                <div class="esg__image">
                    <z-image src="/local/templates/z-template/img/business-model/esg.png" width="100%" />
                </div>
            </div>
        </div>
        <div class="left-col">
            <z-caption
                :decor-right="true"
                tag="h3"
                size="3xl"
                class="u-bottom-margin--s-important"
            >
                <span data-scroll-heading>{{ text.valueForStakeholders }}</span>
            </z-caption>

            <div class="esg__value">
                <z-card theme="grey" class="u-top-margin--s" data-scroll-translate>
                    <template #content>
                        <z-caption
                            :decor-left="true"
                            tag="p"
                            size="m"
                            weight="700"
                            class="u-bottom-margin--s-important"
                        >
                            {{ text.shareholders }}
                        </z-caption>
                        <z-key-indicator
                            :text-left="lang === 'en' ? 'USD' : ''"
                            :value="lang === 'ru' ? '1 475' : '1,475'"
                            :value-text="text.mln"
                            :caption="text.dividends"
                        />
                    </template>
                </z-card>
                <z-card theme="grey" class="u-top-margin--s" data-scroll-translate>
                    <template #content>
                        <z-caption
                            :decor-left="true"
                            tag="p"
                            size="m"
                            weight="700"
                            class="u-bottom-margin--s-important"
                        >
                            {{ text.suppliers }}
                        </z-caption>
                        <z-key-indicator
                            value="95"
                            value-text="%"
                            :caption="text.shareOfRussianCompanies"
                        />
                    </template>
                </z-card>
                <z-card theme="grey" class="u-top-margin--s" data-scroll-translate>
                    <template #content>
                        <z-caption
                            :decor-left="true"
                            tag="p"
                            size="m"
                            weight="700"
                            class="u-bottom-margin--s-important"
                        >
                            {{ text.employees }}
                        </z-caption>
                        <z-key-indicator
                            :text-left="lang === 'en' ? 'USD' : ''"
                            value="193"
                            :value-text="text.mln"
                            :caption="text.socialPrograms"
                            class="u-bottom-margin--xs"
                        />
                        <z-key-indicator
                            :text-left="lang === 'en' ? '> USD' : '>'"
                            :value="lang === 'ru' ? '2' : '2,000'"
                            :value-text="text.thousand"
                            :caption="text.avarageSalary"
                            class="u-bottom-margin--xs"
                        />
                        <z-key-indicator
                            :text-left="lang === 'en' ? 'USD' : ''"
                            value="15"
                            :value-text="text.mln"
                            :caption="text.pensionProgram"
                            class="u-bottom-margin--xs"
                        />
                    </template>
                </z-card>
                <z-card theme="grey" class="u-top-margin--s" data-scroll-translate>
                    <template #content>
                        <z-caption
                            :decor-left="true"
                            tag="p"
                            size="m"
                            weight="700"
                            class="u-bottom-margin--s-important"
                        >
                            {{ text.consumers.title }}
                        </z-caption>
                        <z-key-indicator
                            :text-left="text.consumers.proposal"
                            value="28"
                            :value-text="text.consumers.countries"
                            :caption="text.consumers.caption"
                        />
                    </template>
                </z-card>
                <z-card theme="grey" class="u-top-margin--s" data-scroll-translate>
                    <template #content>
                        <z-caption
                            :decor-left="true"
                            tag="p"
                            size="m"
                            weight="700"
                            class="u-bottom-margin--s-important"
                        >
                            {{ text.state.title }}
                        </z-caption>
                        <z-key-indicator
                            :text-left="lang === 'en' ? 'RUB' : ''"
                            value="281"
                            :value-text="text.state.valueText"
                            :caption="text.state.caption"
                        />
                    </template>
                </z-card>
            </div>
        </div>
    </section>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'resources-result',
    data () {
        return {
            lang: this.$root.lang,
            text: {
                financialResults: localize({
                    ru: '03. ESG и финансовые результаты ',
                    en: '03. ESG and Financial highlights'
                }),
                valueForStakeholders: localize({
                    ru: '04. Ценность для заинтересованных сторон',
                    en: '04. Value for stakeholders'
                }),
                ecology: localize({
                    ru: 'Экология и климат',
                    en: 'Environment and climate'
                }),
                greenhouseGas: localize({
                    ru: 'выбросы парниковых газов от&nbsp;производственной деятельности (охваты&nbsp;1&nbsp;+&nbsp;2)',
                    en: 'GHG emissions from operations (Scope&nbsp;1&nbsp;+&nbsp;2)'
                }),
                greenhouseGasThree: localize({
                    ru: 'выбросы парниковых газов (охват 3)',
                    en: 'GHG emissions (Scope 3)'
                }),
                productionWaste: localize({
                    ru: 'отходов производства являются неопасными',
                    en: 'of the Company’s industrial waste is non-hazardous'
                }),
                shareOfElectricity: localize({
                    ru: 'доля электроэнергии из ВИЭ',
                    en: 'Share of renewables'
                }),
                shareOfReused: localize({
                    ru: 'доля повторно и многократно используемой воды',
                    en: 'Share of reused and recycled water'
                }),
                results: localize({
                    ru: 'Финансовые результаты',
                    en: 'Financial highlights'
                }),
                netProfit: localize({
                    ru: 'чистая прибыль',
                    en: 'Net income'
                }),
                profitability: localize({
                    ru: 'рентабельность EBITDA',
                    en: 'EBITDA margin'
                }),
                netDebt: localize({
                    ru: 'чистый долг / EBITDA',
                    en: 'Net debt/EBITDA'
                }),
                revenueStructure: localize({
                    ru: 'Структура выручки по рынкам сбыта, %',
                    en: 'Revenue by sales market, %'
                }),
                shareholders: localize({
                    ru: 'Акционеры',
                    en: 'Shareholders'
                }),
                dividends: localize({
                    ru: 'было выплачено дивидендов за 2023 год',
                    en: 'Total dividends paid in 2023'
                }),
                suppliers: localize({
                    ru: 'Поставщики',
                    en: 'Suppliers'
                }),
                shareOfRussianCompanies: localize({
                    ru: 'доля российских компаний в структуре поставщиков «Норникеля»',
                    en: 'Share of Russian companies in supplies to Nornickel'
                }),
                employees: localize({
                    ru: 'Сотрудники',
                    en: 'Employees'
                }),
                socialPrograms: localize({
                    ru: 'потрачено на социальные программы для персонала',
                    en: 'Spending on social programmes for employees'
                }),
                avarageSalary: localize({
                    ru: 'средняя заработная плата',
                    en: 'Average monthly pay'
                }),
                pensionProgram: localize({
                    ru: 'расходы на пенсионные программы',
                    en: 'Spending on pension plans'
                }),
                consumers: {
                    title: localize({
                        ru: 'Потребители',
                        en: 'Customers'
                    }),
                    caption: localize({
                        ru: 'поставляется продукция Компании',
                        en: 'The Company’s products are supplied to'
                    }),
                    countries: localize({
                        ru: 'стран',
                        en: 'countries worldwide'
                    }),
                    proposal: localize({
                        ru: 'в',
                        en: ''
                    })
                },
                state: {
                    title: localize({
                        ru: 'Государство',
                        en: 'Government'
                    }),
                    valueText: localize({
                        ru: 'млрд руб. / 3,3 млрд долл. США',
                        en: 'bn / USD 3.3 bn'
                    }),
                    caption: localize({
                        ru: 'налоговые и прочие платежи в бюджеты',
                        en: 'Tax and other payments to budgets'
                    })
                },
                mt: localize({
                    ru: 'млн тонн',
                    en: 'mt'
                }),
                usdBn: localize({
                    ru: 'млрд долл. США',
                    en: 'bn'
                }),
                mln: localize({
                    ru: 'млн долл. США',
                    en: 'mln'
                }),
                thousand: localize({
                    ru: 'тыс. долл. США',
                    en: ''
                }),
            }
        }
    }
}
</script>

<style lang="scss">
.esg {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &__financial-results {
        display: flex;
        justify-content: space-between;
        gap: $token-spacers-2-xl;

        @include breakpoint(v-tablet) {
            flex-direction: column;
            gap: 32px;
        }

        & .z-key-indicator {
            max-width: 514px;
        }
    }

    &__image {
        position: absolute;
        bottom: 0;
        left: -$token-grids-desktop-offset;
        max-width: 30vw;
        z-index: -1;

        @include breakpoint(laptop) {
            left: -$token-grids-laptop-offset;
        }
        @include breakpoint(tablet) {
            left: -$token-grids-tablet-offset;
        }
        @include breakpoint(v-tablet) {
            display: none;
        }
    }

    &__value {
        @include breakpoint(tablet){
            display: grid;
            gap: 32px;

            .z-card {
                margin: 0;
                &:nth-of-type(1){
                    grid-column: 1;
                    grid-row: 1;
                }
                &:nth-of-type(2){
                    grid-column: 1;
                    grid-row: 2;
                }
                &:nth-of-type(3){
                    grid-column: 2;
                    grid-row-start: 1;
                    grid-row-end: 3;
                }
                &:nth-of-type(4){
                    grid-column: 1;
                    grid-row: 3;
                }
                &:nth-of-type(5){
                    grid-column: 2;
                    grid-row: 3;
                }
            }
        }

        @include breakpoint(v-tablet) {
            display: flex;
            flex-direction: column;
        }
    }

    .z-chart {
        &__legend{
            columns: 1;
        }
    }

    .right-col {
        @include breakpoint(tablet) {
            position: relative;
        }
    }
}
</style>
