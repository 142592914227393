<template>
  <div>
    <files
      :data="files"
      :sorting-by-name="sortingByName"
    />
  </div>
</template>

<script>
import { filesAdapter } from './utils/adapters'
import Files from './Files.vue'
export default {
    components: { Files },
    props: {
        sortingByName: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        files () {
            // eslint-disable-next-line no-undef
            return filesAdapter(App?.components?.files || [])
        }
    }
}
</script>

<style lang="scss">
</style>
