<template>
    <nav v-if="list" :class="['nav-mobile', { 'nav-mobile--opened': navOpened }]">
        <a href="#" class="nav-mobile__toggle" @click.prevent="toggleNav">
            <z-icon width="36" height="36" name="menu" v-show="!navOpened"></z-icon>
            <z-icon width="30" height="30" name="close" v-show="navOpened"></z-icon>
        </a>

        <div class="nav-mobile__container" v-show="navOpened">
            <div class="">
                <ul
                    v-show="navOpened"
                    ref="menuHeightStyle"
                    :class="[
                        'nav-mobile__list nav-mobile__list--1',
                        { 'nav-mobile__list--sub-opened': navIndexOpened >= 0 }
                    ]"
                >
                    <li
                        :class="[
                            'container nav-mobile__item nav-mobile__item--1',
                            {
                                'is-opened': indexA == navIndexOpened,
                                'is-active': itemA.active,
                                'is-highlighted': itemA.params.highlighted
                            }
                        ]"
                        v-for="(itemA, indexA) in list"
                        :key="indexA"
                    >
                        <a
                            :href="itemA.link"
                            @click="showChilds($event, indexA, itemA)"
                            :blank="itemA.blank ? '_blank' : false"
                            :class="[
                                'nav-mobile__link nav-mobile__link--1',
                                { 'is-active': itemA.active,
                                  'is-highlighted': itemA.params.highlighted
                            }
                            ]"
                        >
                            <span v-html="itemA.name"></span>
                            <z-icon
                                width="20"
                                height="20"
                                name="round-arrow-folder/arrow"
                                dir="right"
                                v-if="itemA.items && itemA.items.length"
                            ></z-icon>
                        </a>

                        <div class="nav-mobile__childs" v-if="itemA.items">
                            <div class="nav-mobile__inner" ref="menuHeight">
                                <div class="nav-mobile__childs-header">
                                    <button
                                        class="nav-mobile__close"
                                        @click.prevent="hideChilds(indexA)"
                                    >
                                        <z-icon
                                            name="round-arrow"
                                            width="23"
                                            height="23"
                                            dir="left"
                                        ></z-icon>
                                        <span>{{ closeText }}</span>
                                    </button>
                                </div>
                                <div class="nav-mobile__childs-container">
                                    <a
                                        :href="!isRedirect(itemA) ? itemA.link : '#'"
                                        :blank="itemA.blank ? '_blank' : false"
                                        :class="[
                                            'container nav-mobile__link nav-mobile__link--overview',
                                            { 'is-active': checkOverviewActive(itemA.link) }
                                        ]"
                                    >
                                        <span v-html="itemA.name"></span>
                                        <z-icon
                                            v-if="!isRedirect(itemA)"
                                            name="round-arrow"
                                            dir="right"
                                            width="16"
                                            height="16"
                                        />
                                    </a>

                                    <ul class="nav-mobile__list nav-mobile__list--2">
                                        <li
                                            :class="[
                                                'nav-mobile__item nav-mobile__item--2',
                                                {
                                                    'is-active': itemB.active,
                                                    'is-opened': indexB == navChildsIndexOpened,
                                                    'is-highlighted': itemB.params.highlighted
                                                }
                                            ]"
                                            v-for="(itemB, indexB) in clearHide(itemA.items)"
                                            :key="indexB"
                                        >
                                            <a
                                                :href="itemB.link"
                                                :class="[
                                                    'container nav-mobile__link nav-mobile__link--2',
                                                    { 'is-active': itemB.active, 'is-highlighted': itemB.params.highlighted }
                                                ]"
                                                :blank="itemB.blank ? '_blank' : false"
                                                @click="showSubChilds($event, indexB, itemB)"
                                            >
                                                <span v-html="itemB.name"></span>
                                                <z-icon
                                                    width="20"
                                                    height="20"
                                                    name="round-arrow-folder/arrow"
                                                    :dir="navChildsIndexOpened === indexB ? 'up' : 'down'"
                                                    v-if="itemB.items && itemB.items.length"
                                                ></z-icon>
                                            </a>
                                            <div class="nav-mobile__subchilds" v-if="itemB.items">
                                                <ul class="nav-mobile__list nav-mobile__list--3">
                                                    <li
                                                        :class="[
                                                            'nav-mobile__item nav-mobile__item--3',
                                                            { 'is-active': itemC.active,
                                                              'is-highlighted': itemC.params.highlighted }
                                                        ]"
                                                        v-for="(itemC, indexC) in clearHide(
                                                            itemB.items
                                                        )"
                                                        :key="indexC"
                                                    >
                                                        <a
                                                            :href="itemC.link"
                                                            :class="[
                                                                'nav-mobile__link nav-mobile__link--3',
                                                                { 'is-active': itemC.active, 'is-highlighted': itemC.params.highlighted }
                                                            ]"
                                                            :blank="itemC.blank ? '_blank' : false"
                                                            v-html="itemC.name"
                                                        ></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <ul v-show="isNavFooterShown && lang !== 'en'" class="container nav-mobile__footer-list">
                    <li class="nav-mobile__footer-item">
                        <a href="/other-sites/" class="nav-mobile__link-cites">
                            <span v-html="text.otherSites"></span>
                            <z-icon width="16" height="16" name="round-arrow-folder/arrow" dir="right" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'navigation-mobile',
    data () {
        return {
            lang: this.$root?.app?.page?.lang,
            navOpened: false,
            isNavFooterShown: false,
            navIndexOpened: -1,
            navChildsIndexOpened: -1,
            arrowDirection: 'down',
            heightStyles: {},
            closeText: localize({
                ru: 'Главное меню',
                en: 'Main menu'
            }),
            text: {
                otherSites: localize({
                    ru: 'Сайты Группы',
                    en: 'Group Sites'
                }),
                career: localize({
                    ru: 'Карьера',
                    en: 'Career'
                }),
                suppliers: localize({
                    ru: 'Поставщикам',
                    en: 'Suppliers'
                }),
                sale: localize({
                    ru: 'Реализация активов',
                    en: ''
                })
            }
        }
    },
    computed: {
        list () {
            if (!this.$root.app?.components?.navigation) return
            const header = this.$root.app?.components?.navigation?.header || []
            const top = this.$root.app?.components?.navigation?.top || []
            if (top.length) {
                const index = top.findIndex((item) => item.link === '/contacts/')

                if (index) {
                    const nav = top.slice()
                    const contacts = nav.splice(index, 1)
                    return [...nav, ...header, ...contacts]
                }
            }
            return [...top, ...header]
        }
    },
    updated () {
        this.matchHeight()
    },
    methods: {
        matchHeight () {
            if (this.navIndexOpened !== -1) {
                let heightString = this.$refs.menuHeight[this.navIndexOpened].clientHeight + 'px'
                this.$refs.menuHeightStyle.setAttribute('style', `min-height: ${heightString}`)
            } else {
                this.$refs.menuHeightStyle.setAttribute('style', ``)
            }
        },
        buildTarget (href) {
            return /^http|^\/\//.test(href) ? '_blank' : '_self'
        },
        isActive (path) {
            return window.location.pathname === path
        },
        isRedirect (item) {
            return item.params.redirect
        },
        checkOverviewActive (link) {
            return location.pathname === link
        },
        showChilds (event, i, item) {
            if (!item.hasOwnProperty('items')) {
                this.isNavFooterShown = true
                this.hideChilds()
            } else if (
                item.hasOwnProperty('items') &&
                item.items.length &&
                this.navIndexOpened !== i
            ) {
                this.isNavFooterShown = false
                event.preventDefault()
                this.navIndexOpened = i
                this.navChildsIndexOpened = -1
            }
        },
        showSubChilds (event, i, item) {
            if (this.navChildsIndexOpened === i) {
                event.preventDefault()
                this.navChildsIndexOpened = -1
                return
            }

            if (
                item.hasOwnProperty('items') &&
                item.items.length &&
                this.navChildsIndexOpened !== i
            ) {
                event.preventDefault()
                this.navChildsIndexOpened = i
            }
        },
        hideChilds () {
            this.isNavFooterShown = true
            this.navIndexOpened = -1
            this.navChildsIndexOpened = -1
        },
        toggleNav () {
            if (!this.navOpened) {
                this.navOpened = true
                this.isNavFooterShown = true
                // this.MenuOpen(true)
                document.body.style.overflow = 'hidden'
                this.$root.$bus.$emit('navigation-open')
                document.querySelector('body').classList.add('nav-opened')
            } else {
                this.navOpened = false
                this.isNavFooterShown = false
                document.body.style.overflow = ''
                this.$root.$bus.$emit('navigation-close')
                document.querySelector('body').classList.remove('nav-opened')
            }
        },
        clearHide (data) {
            return data.filter((item) => !item.hide)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
