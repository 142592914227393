<template>
    <div class="ir-highlights__stocks u-bottom-margin--2xl">
        <div class="irp-stocks u-bottom-margin--m">
            <z-key-indicator
                size="xs"
                :value="MICEX.value"
                value-text="₽"
                :name="MICEX.changedPercent"
                theme="stock"
            >
                <template v-slot:footer>
                    <span>
                        <z-tooltip icon-hidden placement="bottom" :content="MICEX.tooltip">
                            <span>{{ text.moex }}</span>
                        </z-tooltip>
                    </span>
                </template>
            </z-key-indicator>

            <!-- <z-key-indicator
                size="xs"
                :value="LSE.value"
                value-text="$"
                :name="LSE.change"
                theme="stock"
            >
                <template v-slot:footer>
                    <span>
                        <z-tooltip icon-hidden placement="bottom" :content="LSE.tooltip">
                            <span>LSE</span>
                        </z-tooltip>
                    </span>
                </template>
            </z-key-indicator> -->

            <z-key-indicator
                size="xs"
                v-for="metal in Object.keys(metals)"
                :key="metal"
                :value="metals[metal].value"
                value-text="$"
                :name="metals[metal].change"
                :circle-color="metals[metal].circleColor"
                theme="stock"
            >
                <template v-slot:footer>
                    <span>
                        <z-tooltip icon-hidden placement="bottom" :content="metals[metal].tooltip">
                            <span>{{ metals[metal].key }}</span>
                        </z-tooltip>
                        {{ metals[metal].name }}
                    </span>
                </template>
            </z-key-indicator>
        </div>

        <z-link href="/investors/shareholders/stockchart/">
            <template #left>
                <z-icon name="round-arrow" dir="right" width="32" height="32" />
            </template>
            {{ text.tradingInf }}
        </z-link>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import moment from 'moment'
import axios from 'axios'
import { getFormatedStockData } from '@/utils/stockData.js'

export default {
    name: 'stocks',
    data () {
        return {
            metals: {
                Nickel: {
                    value: '',
                    change: '',
                    tooltip: '',
                    circleColor: '#d1e5f3',
                    key: 'Ni',
                    name: localize({
                        ru: 'Никель',
                        en: 'Nickel'
                    })
                },
                Copper: {
                    value: '',
                    change: '',
                    tooltip: '',
                    circleColor: '#ffeeb3',
                    key: 'Cu',
                    name: localize({
                        ru: 'Медь',
                        en: 'Copper'
                    })
                },
                Palladium: {
                    value: '',
                    change: '',
                    tooltip: '',
                    circleColor: '#e5e8f0',
                    key: 'Pd',
                    name: localize({
                        ru: 'Палладий',
                        en: 'Palladium'
                    })
                },
                Platinum: {
                    value: '',
                    change: '',
                    tooltip: '',
                    circleColor: '#ebecec',
                    key: 'Pt',
                    name: localize({
                        ru: 'Платина',
                        en: 'Platinum'
                    })
                }
            },
            MICEX: {
                value: '',
                change: '',
                tooltip: '',
                changedPercent: ''
            },
            // LSE: {
            //     value: '',
            //     change: '',
            //     tooltip: ''
            // },
            lang: this.$root.lang,
            text: {
                tradingInf: localize({
                    ru: 'Информация о торгах',
                    en: 'Trading information'
                }),
                moex: localize({
                    ru: 'ММВБ',
                    en: 'MOEX'
                })
            }
            // polling: null
        }
    },
    async created () {
        this.loadMetalsStock()
        // this.loadStock()
        // this.pollData()
        this.loadMoex()
    },
    // beforeDestroy () {
    //     clearInterval(this.polling)
    // },
    methods: {
        // pollData () {
        //     this.polling = setInterval(this.loadStock, 300000)
        // },
        getFormattedString (str, dec) {
            let out = parseFloat(str).toFixed(dec)
            if (this.lang === 'ru') {
                out = out.replace(/\./g, ',')
                out = out.replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;')
            } else {
                out = out.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            return out
        },
        getFormattedStringRubles (str, dec) {
            let out = parseFloat(str.replace(',', '.')).toFixed(dec)
            if (this.lang === 'ru') {
                out = out.replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;')
                out = out.replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;')
            } else {
                out = out.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            return out
        },
        // loadStock () {
        //     let LSEValue = this.getFormattedString('13.75', 2)
        //     let tooltipLSE

        //     if (this.lang === 'ru') {
        //         tooltipLSE = `Данные на дату закрытия 28.02.2022 г.`
        //     } else {
        //         tooltipLSE = `Close price as of February 28th`
        //     }

        //     this.LSE = {
        //         value: LSEValue,
        //         // change: LSEChange,
        //         tooltip: tooltipLSE
        //     }
        // },
        loadMetalsStock () {
            axios
                .get(`/local/templates/nornik/json/quotations.json`)
                .then((res) => {
                    let json = res.data
                    for (var key in json) {
                        let value = this.getFormattedString(json[key]['Bid'], 0)
                        let change = this.getFormattedString(json[key]['ChangePercent'], 2)

                        let dayOld = moment(json[key]['Date'], 'YYYY-MM-DD HH:mm:ss')

                        if (dayOld.format('E') === '7') {
                            dayOld.subtract(2, 'days')
                        } else if (dayOld.format('E') === '6') {
                            dayOld.subtract(1, 'days')
                        }

                        let dateFormatted = dayOld.format('DD.MM.YYYY HH:mm')
                        let tooltip = this.lang === 'ru'
                            ? `Данные предоставляются с задержкой минимум на 2 часа по рабочим дням. Данные по состоянию на&nbsp;${dateFormatted}. ${key === 'Nickel' || key === 'Copper' ? 'Фьючерс, 3 мес, LME' : 'Fixing, LBMA'}`
                            : `Market data delayed by 2 hours on business days. Data as of ${dateFormatted}. ${key === 'Nickel' || key === 'Copper' ? 'Futures, 3 months, LME.' : 'Fixing, LBMA'}`


                        this.metals[key].value = value
                        this.metals[key].change = change
                        this.metals[key].tooltip = tooltip

                        // $.each($arStockCharts, function (index, el) {
                        //     $(el)
                        //         .find(`[data-id="${key}"]`)
                        //         .find(`.stockchart__value`)
                        //         .eq(1)
                        //         .find('span')
                        //         .eq(0)
                        //         .html(value)

                        //     $(el)
                        //         .find(`[data-id="${key}"]`)
                        //         .find(`.stockchart__delta`)
                        //         .removeClass(allClasses)
                        //         .addClass(newClass)
                        //         .html(change)

                        //     $(el)
                        //         .find(`[data-id="${key}"]`)
                        //         .find('.tooltip')
                        //         .tooltipster('content', tooltip)
                        // })
                    }

                    return json
                })
                .catch(function (error) {
                    console.error('Error: ' + error)
                })
        },
        formatCbondsData (data) {
            let result = {
                change: '0',
                percent: '0',
                last: '0',
                date: '',
                isNegative: false,
                isPositive: true,
                percentIsNegative: false
            }
            let tickerData = data

            const formatCbondsNumber = (number) =>{
                if (this.lang === 'ru') {
                    return number?.replace(',', '.').replace(' ', '')
                } else {
                    return number?.replace(',', '.')
                }
            }

            if (!(tickerData && tickerData.data)) return
            const lastDataIndex = tickerData.data?.findIndex(item => item.last_price)


            if (lastDataIndex >= 0) {
                const exchangeData = tickerData.data?.[lastDataIndex]
                const exchangeLastPrice = formatCbondsNumber(tickerData.data?.[lastDataIndex + 1]?.last_price) || formatCbondsNumber(exchangeData?.last_price)
                const dateFormat = 'DD.MM.YYYY HH:mm'
                const cbondsDateFormat = 'DD.MM.YYYY HH:mm:ss'
                const showTime = moment(`${exchangeData.date}`, cbondsDateFormat).format('HH:mm') === '00:00'
                const dataFormat = showTime ? dateFormat.replace('HH:mm', '').trim() : dateFormat

                result = {
                    change: this.getFormattedString(formatCbondsNumber(exchangeData?.last_price) - exchangeLastPrice, 2),
                    percent: this.getFormattedString(String(((formatCbondsNumber(exchangeData?.last_price) - exchangeLastPrice) / exchangeLastPrice) * 100), 2),
                    last: this.getFormattedString(formatCbondsNumber(exchangeData?.last_price), 2),
                    date: moment(`${exchangeData.date}`, 'DD.MM.YYYY HH:mm:ss').format(dataFormat),
                    isNegative: formatCbondsNumber(exchangeData.last_price) - exchangeLastPrice < 0,
                    isPositive: formatCbondsNumber(exchangeData.last_price) - exchangeLastPrice > 0
                }
                result.percentIsNegative = result.percent[0] === '-'
            }

            return result
        },

        async loadMoex () {
            const { lang } = this.$root
            const url = 'https://widgets.cbonds.ru/api/stocks/get_tradings_full/62568791cdd50/251/1546/1/'
            let stocksResponse = await fetch(url).then(r => r.json())
            // let stockData = stocksResponse?.data?.[0]

            let formattedData = this.formatCbondsData(stocksResponse)

            let tooltipMICEX = this.lang === 'ru'
                ? `Данные предоставляются с&nbsp;задержкой как минимум на&nbsp;15 минут по&nbsp;состоянию на&nbsp;${formattedData.date} (GMT+03:00)`
                : `Market data delayed by 15 minutes as ${formattedData.date} (GMT+03:00)`

            this.MICEX = {
                value: this.getFormattedStringRubles(formattedData.last, 2),
                change: formattedData.change,
                tooltip: tooltipMICEX,
                changedPercent: formattedData.percent
            }
        }
    }
}
</script>

<style lang="scss">
.irp-stocks {
    .z-key-indicator {
        &__circle {
            width: 60px !important;
            height: 60px !important;
        }

        @include breakpoint(tablet) {
            & + .z-key-indicator {
                margin-bottom: $token-spacers-xs;
            }
        }

        @include breakpoint(mobile) {
            &__circle {
                left: 30%;
            }

            &__footer {
                text-align: center;
            }

            &__body {
                align-items: center;
            }
        }

        @media screen and (max-width: 480px) {
            &__circle {
                left: 25%;
            }
        }

        @media screen and (max-width: 380px) {
            &__circle {
                left: 20%;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.irp-stocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -16px;
    margin-left: -16px;
    margin-right: -16px;
    align-items: flex-end;

    .z-key-indicator {
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 16px;

        @include breakpoint(tablet) {
            width: 33.333%;
            flex-basis: 33.333%;
        }

        @include breakpoint(v-tablet) {
            width: 50%;
            flex-basis: 50%;
        }

        @include breakpoint(mobile) {
            width: 100%;
            flex-basis: 100%;
        }
    }
}

@media (max-width: 1359px) {
    .irp-stocks {
        justify-content: flex-start;
    }
}
</style>
