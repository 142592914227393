<template>
    <div ref="imageWrapper" class="before-after">
        <img class="before-after__image before-after__image--after" :src="afterImage" alt="After image"/>
        <div class="before-after__overlay" :style="{ width: `${compareWidth}%` }">
            <img
                class="before-after__image before-after__image--before"
                :src="beforeImage"
                alt="After image"
                :style="{ width: `${width}px` }"
            />
        </div>
        <input
            class="before-after__range"
            type="range"
            min="0"
            max="100"
            :step="step"
            v-model="compareWidth"
            tabindex="-1"
        />
        <div class="before-after__controls-wrapper" :style="{ left: `${compareWidth}%` }">
            <div class="before-after__controls">
                <z-icon class="before-after__arrow before-after__arrow--left" name="round-arrow-folder/arrow" dir="left" color="#eff6fb"/>
                <z-icon class="before-after__arrow before-after__arrow--right" name="round-arrow-folder/arrow" dir="right" color="#eff6fb"/>
            </div>
            <span class="before-after__line"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'before-after',
    props: {
        value: {
            type: Number,
            default: 50
        },
        beforeImage: {
            type: String,
            default: ''
        },
        afterImage: {
            type: String,
            default: ''
        },
        step: {
            type: Number,
            default: 0.1
        }
    },
    data () {
        return {
            width: null,
            compareWidth: this.value
        }
    },
    mounted () {
        this.handleMount()
        window.addEventListener('resize', this.resizeHandler)
    },
    destroyed () {
        window.removeEventListener('resize', this.resizeHandler)
    },
    methods: {
        resizeHandler () {
            this.width = this.$refs.imageWrapper.getBoundingClientRect().width
        },
        handleMount () {
            this.resizeHandler()
            if (!this.width) {
                this.handleMount()
            }
        }
    }
}
</script>

<style lang="scss">
.before-after {
    width: 100%;
    padding-top: calc(100% * 0.56);
    position: relative;
    overflow: hidden;
    border-radius: 0 42px 0 0;

    @include breakpoint(laptop) {
        border-radius: 0 51px 0 0;
    }

    @include breakpoint(tablet) {
        border-radius: 0 21px 0 0;
    }

    @include breakpoint(mobile) {
        border-radius: 0 11px 0 0;
    }

    &__image {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: auto;
        display: block;

        &--before {
            z-index: 2;
            width: 50%;
            position: relative;
        }

        &--before {
            transform: scale(100%);
        }
    }

    &__overlay {
        position: absolute;
        overflow: hidden;
        height: auto;
        top: 0;
    }

    &__range {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 999;
        width: 100%;
        left: 0;
        right: 0;
        height: 50px;
        cursor: ew-resize;
        background: rgba(0, 0, 0, 0.4);
        opacity: 0;
    }

    &__controls-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        height: 100%;
        transform: translate(-50%, -50%);
        z-index: 5;
    }

    &__controls {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background: linear-gradient(123.73deg, #0077C8 20.82%, rgba(0, 119, 200, 0) 99.26%), #004C97;
        box-shadow: 0 5px 5px rgba(0, 32, 51, 0.04), 0 10px 30px rgba(0, 32, 51, 0.12);
        border-radius: 85px;
        width: 77px;
        height: 62px;
        position: relative;
        z-index: 5;

        @include breakpoint(laptop) {
            width: 91px;
            height: 73px;
            border-radius: 102px;
        }

        @include breakpoint(tablet) {
            width: 52px;
            height: 42px;
            box-shadow: 0 3px 3px rgba(0, 32, 51, 0.04), 0 6px 17px rgba(0, 32, 51, 0.12);
            border-radius: 58px;
        }
    }

    &__line {
        content: "";
        position: absolute;
        top: 0;
        width: 4px;
        height: 100%;
        background: linear-gradient(123.73deg, #0077C8 20.82%, rgba(0, 119, 200, 0) 99.26%), #004C97;
        z-index: 4;
        pointer-events: none;
        user-select: none;
    }

    &__arrow {
        position: absolute;
        width: 26px;
        height: 26px;

        @include breakpoint(laptop) {
            width: 30px;
            height: 30px;
        }

        @include breakpoint(tablet) {
            width: 18px;
            height: 18px;
        }

        &--left {
            left: 12px;

            @include breakpoint(laptop) {
                left: 14px;
            }

            @include breakpoint(tablet) {
                left: 9px;
            }
        }

        &--right {
            right: 12px;

            @include breakpoint(laptop) {
                right: 14px;
            }

            @include breakpoint(tablet) {
                right: 9px;
            }
        }
    }
}
</style>
