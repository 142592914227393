/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'round-arrow-folder/line-l': {
    width: 80,
    height: 80,
    viewBox: '0 0 80 80',
    data: '<circle pid="0" cx="40" cy="40" r="39.5" _stroke="#0077C8"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M51.119 39.393a.498.498 0 00-.126-.21L39.897 28.085a.5.5 0 10-.707.707l10.243 10.243H28.447a.5.5 0 100 1h20.985L39.19 50.279a.5.5 0 10.707.707l11.09-11.09a.498.498 0 00.132-.503z" _fill="#0077C8"/>'
  }
})
