<template>
    <section class="resources-result">
        <div class="left-col">
            <z-caption
                :decor-right="true"
                tag="h3"
                size="3xl"
                class="u-bottom-margin--s-important"
            >
                <span data-scroll-heading>{{ text.firstTitle }}</span>
            </z-caption>
            <div class="resources-result__resources">
                <z-card theme="grey" data-scroll-translate>
                    <template #content>
                        <z-caption
                            :decor-left="true"
                            tag="p"
                            size="m"
                            weight="700"
                            class="u-bottom-margin--s-important"
                        >
                            {{ text.minerals }}
                        </z-caption>
                        <z-key-indicator
                            :value="text.copperNickelValue"
                            :value-text="text.mt"
                            :caption="text.copperNickel"
                            class="u-bottom-margin--m"
                        />
                        <z-key-indicator
                            value="283"
                            :value-text="text.mt"
                            :caption="text.goldIron"
                        />
                    </template>
                </z-card>
                <z-card theme="grey" data-scroll-translate>
                    <template #content>
                        <z-caption
                            :decor-left="true"
                            tag="p"
                            size="m"
                            weight="700"
                            class="u-bottom-margin--s-important"
                        >
                            {{ text.staff }}
                        </z-caption>
                        <z-key-indicator
                            text-left=">"
                            value="80"
                            :value-text="text.thousand"
                            :caption="text.employees"
                        />
                    </template>
                </z-card>
                <z-card theme="grey" data-scroll-translate>
                    <template #content>
                        <z-caption
                            :decor-left="true"
                            tag="p"
                            size="m"
                            weight="700"
                            class="u-bottom-margin--s-important"
                        >
                            {{ text.productionAssets }}
                        </z-caption>
                        <z-key-indicator
                            value="9"
                            :value-text="text.mines"
                            class="u-bottom-margin--xs"
                        />
                        <z-key-indicator
                            value="4"
                            :value-text="text.processingPlants"
                            class="u-bottom-margin--xs"
                        />
                        <z-key-indicator
                            value="4"
                            :value-text="text.metallurgicalPlant"
                        />
                    </template>
                </z-card>
                <z-card theme="grey" data-scroll-translate>
                    <template #content>
                        <z-caption
                            :decor-left="true"
                            tag="p"
                            size="m"
                            weight="700"
                            class="u-bottom-margin--s-important"
                        >
                            {{ text.auxiliaryAssets.title }}
                        </z-caption>
                        <z-list>
                            <z-list-item>{{ text.auxiliaryAssets.transport }}</z-list-item>
                            <z-list-item>{{ text.auxiliaryAssets.energy }}</z-list-item>
                            <z-list-item>{{ text.auxiliaryAssets.global }}</z-list-item>
                            <z-list-item>{{ text.auxiliaryAssets.institute }}</z-list-item>
                        </z-list>
                    </template>
                </z-card>
            </div>
        </div>
        <div class="right-col">
            <z-caption
                :decor-right="true"
                tag="h3"
                size="3xl"
                class="u-bottom-margin--s-important"
            >
                <span data-scroll-heading>{{ text.productionResults }}</span>
            </z-caption>

            <div class="resources-result__content">
                <div class="resources-result__stats">
                    <div class="resources-result__card u-bottom-margin--l" data-scroll-translate>
                        <z-caption
                            :decor-left="true"
                            tag="p"
                            size="m"
                            weight="700"
                            class="u-bottom-margin--s-important"
                        >
                            {{ text.nnDivision }}
                        </z-caption>
                        <div class="resources-result__card-content">
                            <z-key-indicator
                                :value="text.oreValue"
                                :value-text="text.mt"
                                :caption="text.ore"
                                :column="true"
                            />
                            <div class="resources-result__card-stats">
                                <div class="resources-result__card-indicator">
                                    <div class="resources-result__card-item resources-result__card-item--ni">Ni</div>
                                    <div class="resources-result__card-value">{{ text.niNorilsk }}</div>
                                </div>
                                <div class="resources-result__card-indicator">
                                    <div class="resources-result__card-item resources-result__card-item--cu">Cu</div>
                                    <div class="resources-result__card-value">{{ text.cuNorilsk }}</div>
                                </div>
                                <div class="resources-result__card-indicator">
                                    <div class="resources-result__card-item resources-result__card-item--mpg">{{ text.pgm }}</div>
                                    <div class="resources-result__card-value">{{ text.pgmValue }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="resources-result__card u-bottom-margin--l" data-scroll-translate>
                        <z-caption
                            :decor-left="true"
                            tag="p"
                            size="m"
                            weight="700"
                            class="u-bottom-margin--s-important"
                        >
                            {{ text.kolskiDivision }}
                        </z-caption>
                        <div class="resources-result__card-content">
                            <z-key-indicator
                                :value="text.oreValueKola"
                                :value-text="text.mt"
                                :caption="text.ore"
                                :column="true"
                            />
                            <div class="resources-result__card-stats">
                                <div class="resources-result__card-indicator">
                                    <div class="resources-result__card-item resources-result__card-item--ni">Ni</div>
                                    <div class="resources-result__card-value">{{ text.niKola }}</div>
                                </div>
                                <div class="resources-result__card-indicator">
                                    <div class="resources-result__card-item resources-result__card-item--cu">Cu</div>
                                    <div class="resources-result__card-value">{{ text.cuKola }}</div>
                                </div>
                                <div class="resources-result__card-indicator">
                                    <div class="resources-result__card-item resources-result__card-item--mpg">{{ text.pgm }}</div>
                                    <div class="resources-result__card-value">{{ text.pgmKolaValue }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="resources-result__card u-bottom-margin--l" data-scroll-translate>
                        <z-caption
                            :decor-left="true"
                            tag="p"
                            size="m"
                            weight="700"
                            class="u-bottom-margin--s-important"
                        >
                            {{ text.transbaikalDivision }}
                        </z-caption>
                        <div class="resources-result__card-content">
                            <z-key-indicator
                                :value="text.oreValueBaikal"
                                :value-text="text.mt"
                                :caption="text.ore"
                                :column="true"
                            />
                            <div class="resources-result__card-stats">
                                <div class="resources-result__card-indicator">
                                    <div class="resources-result__card-item resources-result__card-item--cu">Cu</div>
                                    <div class="resources-result__card-value">{{ text.cuBaikal }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="resources-result__card u-bottom-margin--l" data-scroll-translate>
                        <z-caption
                            :decor-left="true"
                            tag="p"
                            size="m"
                            weight="700"
                            class="u-bottom-margin--s-important"
                        >
                            {{ text.energyDivision }}
                        </z-caption>
                        <z-key-indicator
                            :value="text.naturalGasValue"
                            :value-text="text.mcm"
                            :caption="text.naturalGas"
                            class="u-bottom-margin--s"
                        />
                        <z-key-indicator
                            value="85"
                            :value-text="text.kt"
                            :caption="text.gasCondensate"
                        />
                    </div>
                    <div class="resources-result__production" data-scroll-translate>
                        <z-caption
                            tag="h3"
                            size="2xl"
                            weight="600"
                            class="u-bottom-margin--xs-important"
                        >
                            {{ text.groupProduction }}
                        </z-caption>
                        <div class="resources-result__indicator-wrapper">
                            <div class="resources-result__indicator">
                                <div class="resources-result__indicator-name resources-result__indicator-name--blue">Ni</div>
                                <div class="resources-result__indicator-text">
                                    <span class="resources-result__indicator-value">209</span>
                                    <span class="resources-result__indicator-desc">{{ text.kt }}</span>
                                </div>
                            </div>
                            <div class="resources-result__indicator">
                                <div class="resources-result__indicator-name resources-result__indicator-name--blue-secondary">Cu</div>
                                <div class="resources-result__indicator-text">
                                    <span class="resources-result__indicator-value">425</span>
                                    <span class="resources-result__indicator-desc">{{ text.kt }}</span>
                                </div>
                            </div>
                            <div class="resources-result__indicator">
                                <div class="resources-result__indicator-name resources-result__indicator-name--bystrinsky">Pd</div>
                                <div class="resources-result__indicator-text">
                                    <span class="resources-result__indicator-value">{{ text.kozValue }}</span>
                                    <span class="resources-result__indicator-desc">{{ text.koz }}</span>
                                </div>
                            </div>
                            <div class="resources-result__indicator">
                                <div class="resources-result__indicator-name resources-result__indicator-name--green">Pt</div>
                                <div class="resources-result__indicator-text">
                                    <span class="resources-result__indicator-value">664</span>
                                    <span class="resources-result__indicator-desc">{{ text.koz }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="resources-result__image">
                    <z-image src="/local/templates/z-template/img/business-model/business-model.png" width="100%" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'resources-result',
    data () {
        return {
            text: {
                firstTitle: localize({
                    ru: '01. Ресурсы',
                    en: '01. Resources'
                }),
                minerals: localize({
                    ru: 'Минерально-сырьевая база',
                    en: 'Mineral resource base'
                }),
                copperNickel: localize({
                    ru: 'доказанные и вероятные запасы сульфидной медно-никелевой руды',
                    en: 'Proven and probable reserves copper-nickel sulphide ores'
                }),
                copperNickelValue: localize({
                    ru: '1 267',
                    en: '1,267'
                }),
                goldIron: localize({
                    ru: 'доказанные и вероятные запасы золото-железо-медной руды',
                    en: 'Proven and probable (gold-iron-copper ores)'
                }),
                staff: localize({
                    ru: 'Персонал',
                    en: 'Workforce'
                }),
                employees: localize({
                    ru: 'сотрудников',
                    en: 'employees'
                }),
                productionAssets: localize({
                    ru: 'Производственные активы',
                    en: 'Mining and metallurgical assets'
                }),
                mines: localize({
                    ru: 'рудников',
                    en: 'mines'
                }),
                processingPlants: localize({
                    ru: 'обогатительные фабрики',
                    en: 'concentration facilities'
                }),
                metallurgicalPlant: localize({
                    ru: 'металлургических завода',
                    en: 'metallurgical plants'
                }),
                auxiliaryAssets: {
                    title: localize({
                        ru: 'Вспомогательные активы',
                        en: 'Auxiliary assets'
                    }),
                    transport: localize({
                        ru: 'Транспортные предприятия',
                        en: 'Transport enterprises'
                    }),
                    energy: localize({
                        ru: 'Энергетические предприятия',
                        en: 'Energy enterprises'
                    }),
                    global: localize({
                        ru: 'Глобальная сбытовая сеть',
                        en: 'Global sales network'
                    }),
                    institute: localize({
                        ru: 'НИОКР — институт «Гипроникель»',
                        en: 'R&D: Gipronickel Institute'
                    })

                },
                mt: localize({
                    ru: 'млн тонн',
                    en: 'mt'
                }),
                thousand: localize({
                    ru: 'тыс.',
                    en: 'thousand'
                }),
                mcm: localize({
                    ru: 'млн м3',
                    en: 'mcm'
                }),
                kt: localize({
                    ru: 'тыс. тонн',
                    en: 'kt'
                }),
                koz: localize({
                    ru: 'тыс. тр. унций',
                    en: 'koz'
                }),
                kozValue: localize({
                    ru: '2 692',
                    en: '2,692'
                }),
                niNorilsk: localize({
                    ru: '1,14%',
                    en: '1.14%'
                }),
                cuNorilsk: localize({
                    ru: '1,98%',
                    en: '1.98%'
                }),
                pgm: localize({
                    ru: 'МПГ',
                    en: 'PGMs'
                }),
                pgmValue: localize({
                    ru: '6,48 г/т',
                    en: '6.48 g/t'
                }),
                niKola: localize({
                    ru: '0,52%',
                    en: '0.52%'
                }),
                cuKola: localize({
                    ru: '0,22%',
                    en: '0.22%'
                }),
                pgmKolaValue: localize({
                    ru: '0,08 г/т',
                    en: '0.08 g/t'
                }),
                cuBaikal: localize({
                    ru: '0,63%',
                    en: '0.63%'
                }),
                productionResults: localize({
                    ru: '02. Производственные результаты',
                    en: '02. Performance highlights'
                }),
                nnDivision: localize({
                    ru: 'Норильский дивизион',
                    en: 'Norilsk Division'
                }),
                ore: localize({
                    ru: 'руды',
                    en: 'of ore'
                }),
                oreValue: localize({
                    ru: '19,2',
                    en: '19.2'
                }),
                oreValueKola: localize({
                    ru: '7,2',
                    en: '7.2'
                }),
                oreValueBaikal: localize({
                    ru: '15,0',
                    en: '15.0'
                }),
                kolskiDivision: localize({
                    ru: 'Кольский дивизион',
                    en: 'Kola Division'
                }),
                transbaikalDivision: localize({
                    ru: 'Забайкальский дивизион',
                    en: 'Trans-Baikal Division'
                }),
                energyDivision: localize({
                    ru: 'Энергетический дивизион',
                    en: 'Energy Division'
                }),
                naturalGas: localize({
                    ru: 'природного газа',
                    en: 'of natural gas'
                }),
                naturalGasValue: localize({
                    ru: '2 720',
                    en: '2,720'
                }),
                gasCondensate: localize({
                    ru: 'газового конденсата',
                    en: 'of gas condensate'
                }),
                groupProduction: localize({
                    ru: 'Производство металлов Группой',
                    en: 'Group’s metals production'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.resources-result {
    display: flex;
    flex-wrap: wrap;

    &__resources {
        display: flex;
        flex-direction: column;
        gap: $token-spacers-s;
    }

    &__content {
        padding-top: $token-spacers-2-s;
        display: flex;
        gap: $token-spacers-s;
    }

    &__stats {
        max-width: 48%;

        @include breakpoint(tablet) {
            max-width: 100%;
        }
    }

    &__card {
        &-content {
            display: flex;
            justify-content: space-between;

            @include breakpoint(mobile) {
                flex-wrap: wrap;
            }
        }

        &-stats {
            padding-top: $token-spacers-3-xs;
            width: 266px;
            display: flex;
            gap: $token-spacers-2-xs;
        }

        &-item {
            margin-bottom: 4px;
            padding: 3px $token-spacers-2-xs;
            width: 78px;
            font-size: 20px;
            font-weight: 600;
            color: #ffffff;
            text-align: center;
            &--ni {
                background: $token-colors-blue;
            }
            &--cu {
                background: $token-colors-navy-blue;
            }
            &--mpg {
                background: $token-colors-gray-40;
            }
        }

        &-value {
            font-size: 22px;
            font-weight: 600;
            text-align: center;
            color: $token-colors-gray-40;
        }
    }

    &__indicator-wrapper {
        padding-top: $token-spacers-s;
        display: flex;
        flex-wrap: wrap;
        gap: $token-spacers-s;
        border-top: 1px solid #3F414F;

        @include breakpoint(mobile) {
            flex-direction: column;
        }
    }

    &__indicator {
        max-width: calc(50% - 16px);
        flex-basis: calc(50% - 16px);
        display: flex;
        align-items: center;
        gap: $token-spacers-2-xs;

        @include breakpoint(mobile) {
            max-width: 100%;
            flex-basis: 100%;
        }

        &-name {
            font-size: 64px;
            font-weight: 700;
            line-height: 1.3;

            @include breakpoint(tablet) {
                font-size: 48px;
            }

            @include breakpoint(mobile) {
                width: 63px;
            }

            &--blue {
                color: $token-colors-blue;
            }
            &--blue-secondary {
                color: $token-colors-navy-blue;
            }
            &--bystrinsky{
                color: $token-colors-bystrinsky;
            }
            &--green {
                color: #A0C1A9;
            }
        }

        &-value {
            display: block;
            font-size: 32px;
            font-weight: 600;
            line-height: 1;
            color: $token-colors-gray-60;
        }
        &-desc {
            display: block;
            line-height: 1;
        }
    }

    &__image {
        margin-right: -$token-grids-desktop-offset;
        flex: 1 1;

        @include breakpoint(laptop) {
            margin-right: -$token-grids-laptop-offset;
        }

        @include breakpoint(tablet) {
            display: none;
        }

    }
}
</style>
