/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-long-3': {
    width: 9,
    height: 66,
    viewBox: '0 0 9 66',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.832.126a.5.5 0 00-.664 0l-4 3.556a.5.5 0 00.664.747L4 1.613V65.5a.5.5 0 001 0V1.613L8.168 4.43a.5.5 0 10.664-.747l-4-3.556z" _fill="#0077C8"/>'
  }
})
