<template>
    <div class="cn-steps">
        <div class="container">
            <div class="cn-steps__wrapper">
                <div class="row">
                    <div class="col-default-4 col-desktop-6 col-tablet-12">
                        <div class="cn-steps__title">
                            <span>Работы по проекту</span><span>идут круглый год</span>
                        </div>

                        <z-button size="l" kind="secondary" href="/clean-norilsk/process/features/" tag="a">Как мы работаем
                            <template v-slot:right>
                                <z-icon name="arrow-long" dir="right" width="18" height="18"></z-icon>
                            </template>
                        </z-button>
                    </div>
                    <div class="col-default-8 col-desktop-6 col-tablet-12">
                        <div class="cn-steps__items cn-steps__items--top">
                            <div class="cn-steps__item">
                                <img class="cn-steps__image" src="/images/clean-norilsk/item-1.png" alt="step." />
                                <div class="cn-steps__text">
                                    <div class="cn-steps__number">1</div>
                                    <a class="cn-steps__name" href="/clean-norilsk/process/preparation/">Подготовка</a>
                                </div>
                            </div>
                            <div class="cn-steps__item">
                                <img class="cn-steps__image" src="/images/clean-norilsk/item-2.png" alt="step." />
                                <div class="cn-steps__text">
                                    <div class="cn-steps__number">2</div>
                                    <a class="cn-steps__name" href="/clean-norilsk/process/dismantling/">Демонтаж</a>
                                </div>
                            </div>
                            <div class="cn-steps__item">
                                <img class="cn-steps__image" src="/images/clean-norilsk/item-3.png" alt="step." />
                                <div class="cn-steps__text">
                                    <div class="cn-steps__number">3</div>
                                    <a class="cn-steps__name" href="/clean-norilsk/process/waste-collection/">Уборка отходов</a>
                                </div>
                            </div>
                            <img class="cn-steps__line" src="/images/clean-norilsk/line.svg" alt="step." />
                        </div>
                    </div>
                    <div class="col-default-11 col-desktop-12 col-tablet-12">
                        <div class="cn-steps__items cn-steps__items--bottom">
                            <div class="cn-steps__item cn-steps__item--first">
                                <img class="cn-steps__image" src="/images/clean-norilsk/item-4.png" alt="step." />
                                <div class="cn-steps__text">
                                    <div class="cn-steps__number">4</div>
                                    <a class="cn-steps__name" href="/clean-norilsk/process/sorting/">Сортировка</a>
                                </div>
                            </div>
                            <div class="cn-steps__item cn-steps__item--second">
                                <img class="cn-steps__image" src="/images/clean-norilsk/item-5.png" alt="step." />
                                <div class="cn-steps__text">
                                    <div class="cn-steps__number">5</div>
                                    <a class="cn-steps__name" href="/clean-norilsk/process/recycling/">Переработка</a>
                                </div>
                            </div>
                            <div class="cn-steps__item cn-steps__item--third">
                                <div class="cn-steps__text">
                                    <div class="cn-steps__number">6</div>
                                    <a class="cn-steps__name" href="/clean-norilsk/process/reclamation/">Рекультивация</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'cn-steps'
}
</script>

<style lang="scss">
@mixin arrow-dot {
    content: '';
    width: 8px;
    height: 8px;
    background: $token-colors-blue-50;
    flex-shrink: 0;
    display: inline-block;
    border-radius: 50%;
}

@mixin arrow-tip {
    content: '';
    width: 10px;
    height: 12px;
    background-image: url('/images/clean-norilsk/tip.svg');
    background-size: 100%;
    flex-shrink: 0;
    display: inline-block;
}

@mixin arrow-line {
    content: '';
    width: 100%;
    height: 1.5px;
    background: #0290F0;
}

.cn-steps {
    $parent: &;
    width: 100%;
    background: linear-gradient(180.48deg, #0077C8 18.76%, #002D83 74.91%);
    min-height: 1150px;
    position: relative;
    padding: 164px 0 126px;
    bottom: -1px;

    @include breakpoint(tablet) {
        padding: 68px 0 0;
    }

    @include breakpoint(mobile) {
        padding: 32px 0 0;
    }

    @media (max-width: 580px) {
        min-height: max-content;
    }

    &__wrapper {
        position: relative;

        &::after {
            content: '';
            width: 800px;
            height: 734px;
            background-image: url('/images/clean-norilsk/bg-steps.png');
            background-repeat: no-repeat;
            position: absolute;
            left: -210px;
            bottom: -170px;

            @include breakpoint(laptop) {
                left: -$token-spacers-3-xl;
            }

            @include breakpoint(tablet) {
                width: 706px;
                height: 648px;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                background-size: contain;
                position: relative;
                display: block;
            }

            @include breakpoint(v-tablet) {
                max-width: 600px;
                width: 100%;
                background-image: url('/images/clean-norilsk/bg-steps_mobile.png');
                height: 550.71px;
            }

            @media (max-width: 580px) {
                height: 300px;
                max-width: 320px;
            }
        }
    }

    &__title {
        display: flex;
        flex-direction: column;
        font-size: 64px;
        line-height: 120%;
        margin-top: 100px;
        margin-bottom: 72px;
        letter-spacing: -0.03em;
        width: max-content;
        align-items: center;

        span {
            width: max-content;
            display: block;
            background: linear-gradient(180deg, #FFFFFF 0%, #8BBBFF 100%), #FFFFFF;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 300;

            &:last-of-type {
                font-weight: 700;
            }
        }

        @media (max-width: 1800px) {
            font-size: 54px;
        }

        @include breakpoint(laptop) {
            font-size: 64px;
            margin-top: $token-spacers-s;
        }

        @include breakpoint(tablet) {
            margin-top: $token-spacers-s;
            width: 100%;
            align-items: center;
        }

        @media (max-width: 1200px) {
            font-size: 48px;
        }

        @include breakpoint(v-tablet) {
            margin-top: $token-spacers-s;
            margin-bottom: $token-spacers-s;
        }

        @media (max-width: 580px) {
            display: none;
        }
    }

    &__item {
        width: 33%;
        position: relative;
        display: flex;
        flex-direction: column;

        @include breakpoint(laptop) {
            width: 100%;
            flex-direction: row;
            margin-bottom: 24px;
        }

        &::before {
            content: '';
            width: 100%;
            height: 330px;
            background-image: url('/images/clean-norilsk/item-gradient.png');
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            margin-bottom: $token-spacers-xs;

            @include breakpoint(laptop) {
                width: 200px;
                height: 200px;
                flex-shrink: 0;
                margin-bottom: 0;
            }

            @include breakpoint(tablet) {
                position: absolute;
                left: 30%;
            }

            @include breakpoint(v-tablet) {
                left: 0;
            }

            @include breakpoint(mobile) {
                display: none;
            }
        }
    }

    &__image {
        position: absolute;
        display: block;

        @include breakpoint(laptop) {
            width: 200px;
            height: 200px;
        }

        @include breakpoint(tablet) {
            position: relative;
            left: 30%;
        }

        @include breakpoint(v-tablet) {
            left: 0;
        }

        @media (max-width: 580px) {
            display: none;
        }
    }

    &__line {
        position: absolute;
        bottom: -418px;
        right: 13px;

        @media (max-width: 1900px) {
            right: 0px;
        }

        @media (max-width: 1800px) {
            right: -24px;
        }

        @media (max-width: 1500px) {
            right: -36px;
        }
    }

    &__text {
        display: flex;
        align-items: center;
        width: 100%;

        @include breakpoint(tablet) {
            position: relative;
            left: 30%;
            width: auto;
        }

        @include breakpoint(v-tablet) {
            left: 0;
        }
    }

    &__number {
        width: 72px;
        height: 64px;
        background: linear-gradient(123.73deg, #0077C8 20.82%, rgba(0, 119, 200, 0) 99.26%), #004C97;
        box-shadow: 0px 4px 4px rgba(0, 32, 51, 0.04), 0px 8px 24px rgba(0, 32, 51, 0.12);
        border-radius: 80px 80px 0 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 25px;
        line-height: 1.2;
        color: #FFFFFF;
        margin-right: $token-spacers-2-xs;
        flex-shrink: 0;

        @include breakpoint(laptop) {
            margin-right: $token-spacers-2-xs;
        }

        @media (max-width: 1200px) {
            margin-right: $token-spacers-3-xs;
        }

        @include breakpoint(tablet) {
            margin-right: $token-spacers-2-xs;
        }

        @include breakpoint(mobile) {
            width: 54px;
            height: 48.5px;
            font-size: 18.75px;
            margin-right: $token-spacers-2-xs;
        }
    }

    &__name {
        font-weight: 600;
        font-size: 30px;
        line-height: 120%;
        color: #FFFFFF;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        text-decoration: none;

        &:hover {
            color: #25A7FF;
            text-decoration-line: underline;
        }

        @media (max-width: 1800px) {
            font-size: 28px;
        }

        @include breakpoint(mobile) {
            font-size: 24px;
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: flex-end;
        height: 100%;

        @include breakpoint(laptop) {
            flex-direction: column;
        }

        &--top {
            position: relative;

            #{$parent}__item::before,
            #{$parent}__image,
            #{$parent}__text {
                margin-left: -50px;

                @include breakpoint(laptop) {
                    margin-left: 0;
                }
            }

            #{$parent}__name::after {
                @include arrow-dot;
                margin-left: $token-spacers-2-xs;

                @media (max-width: 1650px) {
                    margin-left: $token-spacers-3-xs;
                }
            }

            #{$parent}__text {
                &::after {
                    @include arrow-line;
                }

                &::before {
                    @include arrow-tip;
                    margin-right: $token-spacers-2-xs;

                    @include breakpoint(laptop) {
                        margin-right: $token-spacers-3-xs;
                    }
                }
            }

            #{$parent}__item:first-of-type #{$parent}__text::before {
                display: none;
            }

            #{$parent}__item:last-of-type #{$parent}__text::after,
            #{$parent}__item:last-of-type #{$parent}__name::after {
                display: none;
            }

            #{$parent}__item:last-of-type #{$parent}__image {
                margin-left: 0;

                @media (max-width: 1800px) {
                    margin-left: -25px;
                }

                @include breakpoint(laptop) {
                    margin-left: 0;
                }
            }
        }

        &--bottom {
            flex-direction: row-reverse;
            margin-top: 12px;
            position: relative;

            @include breakpoint(laptop) {
                position: inherit;
                margin-top: 0;
                display: grid;
                width: 100%;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);

                #{$parent}__item--first {
                    grid-column: 2 / 3;
                    grid-row: 1 / 2;
                    padding-left: $token-spacers-2-xs;
                }

                #{$parent}__item--second {
                    grid-column: 2 / 3;
                    grid-row: 2 / 3;
                    padding-left: $token-spacers-2-xs;
                }

                #{$parent}__item--third {
                    grid-column: 1 / 2;
                    grid-row: 2 / 3;
                    align-self: center;

                    #{$parent}__text {
                        justify-content: center;
                    }
                }
            }

            @include breakpoint(tablet) {
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: unset;

                #{$parent}__item--first,
                #{$parent}__item--second {
                    grid-column: 1 / 1;
                    padding: 0;
                }
            }

            #{$parent}__text,
            #{$parent}__item::before,
            #{$parent}__image {
                margin-left: 80px;

                @include breakpoint(laptop) {
                    margin-left: 0;
                }
            }

            #{$parent}__text {
                &::before {
                    @include arrow-dot;
                    margin-right: $token-spacers-2-xs;

                    @include breakpoint(laptop) {
                        margin-right: $token-spacers-3-xs;
                    }
                }

                &::after {
                    @include arrow-line;
                }
            }

            #{$parent}__name {

                &::after {
                    @include arrow-tip;
                    transform: rotate(180deg);
                    margin-left: $token-spacers-2-xs;

                    @include breakpoint(laptop) {
                        margin-left: $token-spacers-3-xs;
                    }
                }
            }

            #{$parent}__item:first-of-type #{$parent}__text::after,
            #{$parent}__item:first-of-type #{$parent}__name::after {
                display: none;
            }

            #{$parent}__item:last-of-type #{$parent}__text::before {
                display: none;
            }

            #{$parent}__item:last-of-type {
                @include breakpoint(tablet) {
                    position: absolute;
                    width: max-content;
                    z-index: 1;
                    bottom: -500px;
                    left: 50%;
                    transform: translateX(-11%);
                }

                @include breakpoint(v-tablet) {
                    bottom: -400px;
                }

                @media (max-width: 580px) {
                    bottom: -250px;
                    left: 30%;
                }

                &:before {
                    display: none;
                }
            }
        }
    }

    .z-button {
        background: #fff;
        font-weight: 600;
        margin-bottom: 32px;

        @include breakpoint(v-tablet) {
            min-height: 40px !important;
            padding: 7px 12px;
        }

        @include breakpoint(mobile) {
            justify-content: space-between;
            padding: 0 0 0 20px;
            width: max-content !important;
        }

        @media (max-width: 580px) {
            margin-top: 32px;
            width: 100% !important;
        }
    }

    .z-button .z-button__icon {
        @include breakpoint(v-tablet) {
            margin: -16px -12px -16px 8px !important;
            width: 40px !important;
            height: 40px !important;
        }
        @include breakpoint(mobile) {
            display: flex;
            margin: 0 0 0 16px !important;
        }
    }

    .row {
        justify-content: flex-end;
        position: relative;
        z-index: 1;

        > [class*="col-"] {
            margin-top: 0;

            @include breakpoint(tablet) {
                text-align: center;
                align-items: center;
            }
        }
    }

    @include breakpoint(laptop) {
        #{$parent}__text::before,
        #{$parent}__text::after,
        #{$parent}__name::after,
        #{$parent}__line {
            display: none
        }
    }
}
</style>
