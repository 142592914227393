<template>
    <div
        :class="[
            'slide-content',
            { 'slide-content--full-height': fullHeight },
        ]"
        :style="`background-image: url(${image})`"
    >
        <div
            class="slide-content__overlay"
            :style="`opacity: ${opacity}`"
        ></div>
        <div class="container u-relative">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'slide-content',
    props: {
        image: {
            type: String,
            required: true
        },
        opacity: {
            type: String,
            default: '0'
        },
        fullHeight: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {}
    }
}
</script>

<style lang="scss">
.slide-content {
    min-height: 300px;
    background-position: center;
    background-size: cover;
    position: relative;

    @include padding-level(top, 3XL);
    @include padding-level(bottom, 3XL);

    @include breakpoint(tablet) {
        padding-bottom: $token-spacers-3-xl;
    }

    @include breakpoint (mobile) {
        padding: 32px;
        padding-bottom: 72px;
    }

    &__overlay {
        &:before {
            content: '';
            position: absolute;
            background-color: black;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    &--full-height {
        height: 100%;
    }
}
</style>
