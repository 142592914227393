<template>
    <div class="contacts-page-vue container">
        <z-accordion>
            <z-accordion-item id="1">
                <template v-slot:header>
                    <span v-html="text.nornickel"></span>
                </template>
                <template v-slot:body>
                    <div class="contacts-page-vue__wrapper u-bottom-padding--2xs">
                        <z-caption tag="h2" size="xl" decor-left>
                            <span v-html="text.nornickel"></span>
                        </z-caption>
                        <contact-card-hoc class="u-bottom-margin--m" :show-name="false" :show-description="false" id="1" horizontal goal-contactssent />
                        <contacts-page-form class="u-bottom-margin--m"></contacts-page-form>
                    </div>
                    <z-accordion nested>
                        <z-accordion-item id="2">
                            <template v-slot:header>
                                <span v-html="text.insiders"></span>
                            </template>
                            <template v-slot:body>
                                <!-- <z-caption tag="h3" size="xl" decor-left>
                                    <span v-html="text.generalQuestions"></span>
                                </z-caption> -->
                                <contact-card-hoc class="u-bottom-margin--m" :show-address="false" :show-name="false" :show-description="false" id="1-2" horizontal/>
                            </template>
                        </z-accordion-item>
                    </z-accordion>
                    <z-accordion nested>
                        <z-accordion-item id="2-1">
                            <template v-slot:header>
                                <span v-html="text.corporateDetails"></span>
                            </template>
                            <template v-slot:body>
                                <z-caption tag="h3" size="xl" decor-left>
                                    <span v-html="text.corporateDetails"></span>
                                </z-caption>
                                <div class="row u-bottom-margin--xl">
                                    <div class="col-default-5 col-tablet-12 u-bottom-margin--m">
                                        <p class="contacts-page-vue__label" v-html="text.fullNameHeader"></p>
                                        <p class="contacts-page-vue__text" v-html="text.fullName"></p>
                                        <p class="contacts-page-vue__text contacts-page-vue__text--fade" v-html="text.fullNameTranslation"></p>
                                    </div>
                                    <div
                                        class="col-default-5 col-tablet-12 col-default-offset-2 col-tablet-offset-0 u-bottom-margin--m">
                                        <p class="contacts-page-vue__label" v-html="text.shortNameHeader"></p>
                                        <p class="contacts-page-vue__text" v-html="text.shortName"></p>
                                        <p class="contacts-page-vue__text contacts-page-vue__text--fade" v-html="text.shortNameTranslation"></p>
                                    </div>
                                    <div class="col-default-5 col-tablet-12">
                                        <p class="contacts-page-vue__label" v-html="text.locationHeader"></p>
                                        <p class="contacts-page-vue__text" v-html="text.location"></p>
                                    </div>
                                </div>
                                <z-caption tag="h4" size="l" v-html="text.stateRegistration"></z-caption>
                                <div class="row u-bottom-margin--xl">
                                    <div class="col-default-5 col-tablet-12 u-bottom-margin--m">
                                        <p class="contacts-page-vue__label" v-html="text.stateRegistrationCertificate"></p>
                                        <p class="contacts-page-vue__text" v-html="text.stateRegistrationNumber"></p>
                                    </div>
                                    <div
                                        class="col-default-5 col-tablet-12 col-default-offset-2 col-tablet-offset-0 u-bottom-margin--m">
                                        <p class="contacts-page-vue__label" v-html="text.stateRegistrationAuthorityHeader"></p>
                                        <p class="contacts-page-vue__text" v-html="text.stateRegistrationAuthority"></p>
                                    </div>
                                    <div class="col-default-5 col-tablet-12 u-bottom-margin--m">
                                        <p class="contacts-page-vue__label" v-html="text.stateRegistrationDateHeader"></p>
                                        <p class="contacts-page-vue__text" v-html="text.stateRegistrationDate">04.07.1997</p>
                                    </div>
                                    <div
                                        class="col-default-5 col-tablet-12 col-default-offset-2 col-tablet-offset-0 u-bottom-margin--m">
                                        <p class="contacts-page-vue__label" v-html="text.stateRegistrationPrimaryNumber"></p>
                                        <p class="contacts-page-vue__text">1028400000298</p>
                                    </div>
                                    <div class="col-default-5 col-tablet-12">
                                        <p class="contacts-page-vue__label" v-html="text.legalEntities"></p>
                                        <p class="contacts-page-vue__text" v-html="text.legalEntitiesDate"></p>
                                    </div>
                                    <div class="col-default-5 col-tablet-12 col-default-offset-2 col-tablet-offset-0">
                                        <p class="contacts-page-vue__label" v-html="text.registrationAuthorityHeader"></p>
                                        <p class="contacts-page-vue__text" v-html="text.registrationAuthority"></p>
                                    </div>
                                </div>
                                <z-caption tag="h4" size="l" v-html="text.taxRegistration">
                                </z-caption>
                                <z-caption tag="p" weight="400" size="l" v-html="text.taxRegistrationAuthority">
                                </z-caption>
                                <div class="row u-bottom-margin--2xl">
                                    <div class="col-default-5 col-tablet-12">
                                        <p class="contacts-page-vue__label" v-html="text.inn"></p>
                                        <p class="contacts-page-vue__text">8401005730</p>
                                    </div>
                                    <div class="col-default-5 col-tablet-12 col-default-offset-2 col-tablet-offset-0">
                                        <p class="contacts-page-vue__label" v-html="text.kpp"></p>
                                        <p class="contacts-page-vue__text">997550001</p>
                                    </div>
                                </div>
                            </template>
                        </z-accordion-item>
                    </z-accordion>
                </template>
            </z-accordion-item>
        </z-accordion>
        <z-accordion>
            <z-accordion-item id="3">
                <template v-slot:header>
                    <span v-html="text.branches"></span>
                </template>
                <template v-slot:body>
                    <div class="contacts-page-vue__wrapper u-bottom-padding--2xs">
                        <z-caption tag="h2" size="xl" decor-left v-html="text.branchesArkhangelsk"></z-caption>
                        <contact-card-hoc class="u-bottom-margin--m" :show-name="false" :show-description="false" id="2" horizontal/>
                        <div class="row">
                            <div class="col-default-5 col-tablet-12 u-bottom-margin--m-important">
                                <contact-card-hoc :show-address="false" id="3"/>
                            </div>
                            <div
                                class="col-default-5 col-tablet-12 u-bottom-margin--m-important col-default-offset-2 col-tablet-offset-0">
                                <contact-card-hoc :show-address="false" id="4"/>
                            </div>
                        </div>
                    </div>
                    <div class="contacts-page-vue__wrapper u-bottom-padding--2xs u-top-margin--xl">
                        <z-caption tag="h2" size="xl" decor-left v-html="text.branchesPolar"></z-caption>
                        <contact-card-hoc class="u-bottom-margin--m" :show-name="false" :show-description="false" id="5" horizontal/>
                        <div class="row">
                            <div class="col-default-5 col-tablet-12 u-bottom-margin--m-important">
                                <contact-card-hoc :show-address="false" id="6"/>
                            </div>
                            <div
                                class="col-default-5 col-tablet-12 u-bottom-margin--m-important col-default-offset-2 col-tablet-offset-0">
                                <contact-card-hoc :show-address="false" id="7"/>
                            </div>
                            <div class="col-default-5 col-tablet-12 u-bottom-margin--m-important">
                                <contact-card-hoc :show-address="false" id="8"/>
                            </div>
                        </div>
                    </div>
                    <div class="contacts-page-vue__wrapper u-bottom-padding--2xs u-top-margin--xl">
                        <z-caption tag="h2" size="xl" decor-left v-html="text.branchesKrasnoyarsk"></z-caption>
                        <contact-card-hoc class="u-bottom-margin--m" :show-name="false" :show-description="false" id="9" horizontal/>
                        <div class="row">
                            <div class="col-default-5 col-tablet-12 u-bottom-margin--m-important">
                                <contact-card-hoc :show-address="false" id="10"/>
                            </div>
                            <div
                                class="col-default-5 col-tablet-12 u-bottom-margin--m-important col-default-offset-2 col-tablet-offset-0">
                                <contact-card-hoc :show-address="false" id="11"/>
                            </div>
                        </div>
                    </div>
                    <div class="u-bottom-padding--2xs u-top-margin--xl">
                        <z-caption tag="h2" size="xl" decor-left v-html="text.branchesMurmansk"></z-caption>
                        <contact-card-hoc class="u-bottom-margin--m" :show-name="false" :show-description="false" id="12" horizontal/>
                        <div class="row">
                            <div class="col-default-5 col-tablet-12 u-bottom-margin--m-important">
                                <contact-card-hoc :show-address="false" id="13"/>
                            </div>
                            <div
                                class="col-default-5 col-tablet-12 u-bottom-margin--m-important col-default-offset-2 col-tablet-offset-0">
                                <contact-card-hoc :show-address="false" id="14"/>
                            </div>
                            <div class="col-default-5 col-tablet-12 u-bottom-margin--m-important">
                                <contact-card-hoc :show-address="false" id="15"/>
                            </div>
                            <div
                                class="col-default-5 col-tablet-12 u-bottom-margin--m-important col-default-offset-2 col-tablet-offset-0">
                                <contact-card-hoc :show-address="false" id="16"/>
                            </div>
                        </div>
                    </div>
                </template>
            </z-accordion-item>
        </z-accordion>
        <z-accordion class="u-bottom-margin--m">
            <z-accordion-item id="4">
                <template v-slot:header>
                    <span v-html="text.asset"></span>
                </template>
                <template v-slot:body>
                    <div class="contacts-page-vue__wrapper u-bottom-padding--2xs">
                        <z-caption tag="h2" size="xl" decor-left v-html="text.assetsKola"></z-caption>
                        <contact-card-hoc class="u-bottom-margin--m" :show-name="false" :show-description="false" id="17" horizontal/>
                    </div>
                    <div class="contacts-page-vue__wrapper u-bottom-padding--2xs u-top-margin--xl">
                        <z-caption tag="h2" size="xl" decor-left v-html="text.assetsPolar"></z-caption>
                        <contact-card-hoc class="u-bottom-margin--m" :show-description="false" id="18" horizontal/>
                    </div>
                    <div class="contacts-page-vue__wrapper u-bottom-padding--2xs u-top-margin--xl">
                        <z-caption tag="h2" size="xl" decor-left v-html="text.assetsBystrinsky"></z-caption>
                        <contact-card-hoc class="u-bottom-margin--m" :show-name="false" :show-description="false"
                                          id="20" horizontal/>
                    </div>
                    <div class="u-bottom-padding--2xs u-top-margin--xl">
                        <z-caption tag="h2" size="xl" decor-left v-html="text.assetsFinland">Norilsk Nickel Harjavalta (Финляндия)</z-caption>
                        <contact-card-hoc class="u-bottom-margin--m" :show-name="false" :show-description="false"
                                          id="21" horizontal/>
                    </div>
                </template>
            </z-accordion-item>
        </z-accordion>
        <div class="content-container">
            <z-caption class="u-bottom-margin--2xl-important" tag="h2" size="3xl" decor-left decor-right>
                <span v-html="text.directionsContacts"></span>
            </z-caption>
            <div class="row">
                <div class="col-default-4 col-tablet-6 col-v-tablet-12">
                    <z-link href="/news-and-media/contacts/">
                        <template v-slot:left>
                            <z-icon name="round-arrow" dir="right" width="24" height="24"/>
                        </template>
                        <span v-html="text.contactsMedia"></span>
                    </z-link>
                </div>
                <div class="col-default-4 col-tablet-6 col-v-tablet-12">
                    <z-link href="/sustainability/corporate-hotline/#contacts">
                        <template v-slot:left>
                            <z-icon name="round-arrow" dir="right" width="24" height="24"/>
                        </template>
                        <span v-html="text.hotline"></span>
                    </z-link>
                </div>
                <div class="col-default-4 col-tablet-6 col-v-tablet-12">
                    <z-link href="/suppliers/contacts/">
                        <template v-slot:left>
                            <z-icon name="round-arrow" dir="right" width="24" height="24"/>
                        </template>
                        <span v-html="text.contactsSuppliers"></span>
                    </z-link>
                </div>
                <div class="col-default-4 col-tablet-6 col-v-tablet-12">
                    <z-link href="/investors/contacts/">
                        <template v-slot:left>
                            <z-icon name="round-arrow" dir="right" width="24" height="24"/>
                        </template>
                        <span v-html="text.contactsInvestors"></span>
                    </z-link>
                </div>
                <div class="col-default-4 col-tablet-6 col-v-tablet-12">
                    <z-link href="/careers/contacts">
                        <template v-slot:left>
                            <z-icon name="round-arrow" dir="right" width="24" height="24"/>
                        </template>
                        <span v-html="text.contactsCareers"></span>
                    </z-link>
                </div>
                <div class="col-default-4 col-tablet-6 col-v-tablet-12">
                    <z-link href="/business/contacts/">
                        <template v-slot:left>
                            <z-icon name="round-arrow" dir="right" width="24" height="24"/>
                        </template>
                        <span v-html="text.contactsSales"></span>
                    </z-link>
                </div>
                <div class="col-default-4 col-tablet-6 col-v-tablet-12">
                    <z-link href="/sustainability/contacts/">
                        <template v-slot:left>
                            <z-icon name="round-arrow" dir="right" width="24" height="24"/>
                        </template>
                        <span v-html="text.contactsEsg"></span>
                    </z-link>
                </div>
                <div class="col-default-4 col-tablet-6 col-v-tablet-12">
                    <z-link href="/innovation/contacts">
                        <template v-slot:left>
                            <z-icon name="round-arrow" dir="right" width="24" height="24"/>
                        </template>
                        <span v-html="text.contactsInnovation"></span>
                    </z-link>
                </div>
                <div class="col-default-4 col-tablet-6 col-v-tablet-12">
                    <z-link href="/non-core-assets/contacts">
                        <template v-slot:left>
                            <z-icon name="round-arrow" dir="right" width="24" height="24"/>
                        </template>
                        <span v-html="text.contactsNonCoreAssets"></span>
                    </z-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
export default {
    name: 'contacts-page',
    data () {
        return {
            text: {
                nornickel: localize({
                    ru: 'ПАО &laquo;ГМК &laquo;Норильский никель&raquo;',
                    en: 'PJSC “MMC “Norilsk Nickel”'
                }),
                branches: localize({
                    ru: 'Филиалы',
                    en: 'Branches'
                }),
                asset: localize({
                    ru: 'Производственные активы',
                    en: 'Production asset'
                }),
                corporateDetails: localize({
                    ru: 'Реквизиты',
                    en: 'Corporate details'
                }),
                insiders: localize({
                    ru: 'Инсайдерам',
                    en: 'Insiders'
                }),
                generalQuestions: localize({
                    ru: 'Общие вопросы',
                    en: 'General questions'
                }),
                fullNameHeader: localize({
                    ru: 'Полное наименование:',
                    en: 'Full name:'
                }),
                fullName: localize({
                    ru: 'Публичное акционерное общество &laquo;Горно-металлургическая компания &laquo;Норильский никель&raquo;',
                    en: 'Public Joint Stock Company “Mining and Metallurgical Company “NORILSK NICKEL”'
                }),
                fullNameTranslation: localize({
                    ru: 'Public Joint Stock Company &laquo;Mining and Metallurgical Company &laquo;NORILSK NICKEL&raquo;',
                    en: ''
                }),
                shortNameHeader: localize({
                    ru: 'Сокращенное наименование:',
                    en: 'Short name:'
                }),
                shortName: localize({
                    ru: 'ПАО &laquo;ГМК &laquo;Норильский никель&raquo;',
                    en: 'PJSC “MMC “NORILSK NICKEL”'
                }),
                shortNameTranslation: localize({
                    ru: 'PJSC &laquo;MMC &laquo;NORILSK NICKEL&raquo;',
                    en: ''
                }),
                locationHeader: localize({
                    ru: 'Место нахождения:',
                    en: 'Location:'
                }),
                location: localize({
                    ru: 'Российская Федерация, Красноярский край, г.&nbsp;Дудинка.',
                    en: 'Dudinka, Krasnoyarsk Territory, Russia'
                }),
                stateRegistration: localize({
                    ru: 'Сведения о&nbsp;государственной регистрации:',
                    en: 'State registration:'
                }),
                stateRegistrationCertificate: localize({
                    ru: 'Номер и&nbsp;серия свидетельства о&nbsp;государственной регистрации (перерегистрации) предприятия:',
                    en: 'Certificate of&nbsp;State Registration (Re-Registration):'
                }),
                stateRegistrationNumber: localize({
                    ru: '&#8470;&nbsp;07&nbsp;серия ОАО',
                    en: '&#8470;&nbsp;07&nbsp;series OAO'
                }),
                stateRegistrationAuthorityHeader: localize({
                    ru: 'Наименование органа, осуществившего государственную регистрацию:',
                    en: 'State registration authority:'
                }),
                stateRegistrationAuthority: localize({
                    ru: 'Администрация Таймырского автономного округа',
                    en: 'Administration of&nbsp;the Taimyrsky (Dolgano-Nenetsky) Autonomous Area'
                }),
                stateRegistrationDateHeader: localize({
                    ru: 'Дата государственной регистрации при создании:',
                    en: 'State registration date:'
                }),
                stateRegistrationDate: localize({
                    ru: '04.07.1997',
                    en: '04 July 1997'
                }),
                stateRegistrationPrimaryNumber: localize({
                    ru: 'Основной регистрационный номер юридического лица:',
                    en: 'Primary state registration number of&nbsp;the legal entity:'
                }),
                legalEntities: localize({
                    ru: 'Дата внесения записи в&nbsp;ЕГРЮЛ о&nbsp;юридическом лице, зарегистрированном до&nbsp;01.07.2002:',
                    en: 'Date of&nbsp;the entry to&nbsp;the Unified State Register of&nbsp;Legal Entities with respect to&nbsp;a&nbsp;legal entity incorporated before 01&nbsp;July 2002:'
                }),
                legalEntitiesDate: localize({
                    ru: '02.09.2002',
                    en: '02 September 2002'
                }),
                registrationAuthorityHeader: localize({
                    ru: 'Наименование регистрирующего органа:',
                    en: 'Registeration authority:'
                }),
                registrationAuthority: localize({
                    ru: 'Межрайонная инспекция Министерства Российской Федерации по&nbsp;налогам и&nbsp;сборам &#8470;&nbsp;2&nbsp;по&nbsp;Таймырскому (Долгано-Ненецкому) автономному округу',
                    en: 'Interdistrict Inspectorate No.&nbsp;2&nbsp;of&nbsp;the Ministry of&nbsp;Taxation of&nbsp;the Russian Federation for Taimyrsky (Dolgano-Nenetsky) Autonomous Area'
                }),
                taxRegistration: localize({
                    ru: 'Сведения о&nbsp;постановке на&nbsp;налоговый учет:',
                    en: 'Taxpayer registration:'
                }),
                taxRegistrationAuthority: localize({
                    ru: 'Межрайонная инспекция Федеральной налоговой службы &#8470;&nbsp;25&nbsp;по&nbsp;Красноярскому краю',
                    en: 'Interdistrict Inspectorate &#8470;&nbsp;25&nbsp;of&nbsp;the Federal Tax Service for the Krasnoyarsk Territory'
                }),
                inn: localize({
                    ru: 'ИНН:',
                    en: 'INN:'
                }),
                kpp: localize({
                    ru: 'КПП:',
                    en: 'KPP:'
                }),
                branchesArkhangelsk: localize({
                    ru: 'Архангельский транспортный филиал',
                    en: 'Arkhangelsk Transport Branch'
                }),
                branchesPolar: localize({
                    ru: 'Заполярный транспортный филиал',
                    en: 'Polar Transport Branch'
                }),
                branchesKrasnoyarsk: localize({
                    ru: 'Красноярский транспортный филиал',
                    en: 'Krasnoyarsk Transport Branch'
                }),
                branchesMurmansk: localize({
                    ru: 'Мурманский транспортный филиал',
                    en: 'Murmansk Transport Branch'
                }),
                assetsKola: localize({
                    ru: 'АО&nbsp;Кольская ГМК (Мурманская область)',
                    en: 'Kola MMC (Murmansk Region)'
                }),
                assetsPolar: localize({
                    ru: 'Заполярный филиал, Норильск',
                    en: 'Polar Branch, Norilsk'
                }),
                assetsBystrinsky: localize({
                    ru: 'Быстринский ГОК Газимурский завод',
                    en: 'Bystrinsky MPP Gazimursky Zavod'
                }),
                assetsFinland: localize({
                    ru: 'Norilsk Nickel Harjavalta (Финляндия)',
                    en: 'Norilsk Nickel Harjavalta (Finland)'
                }),
                directionsContacts: localize({
                    ru: 'Контакты по&nbsp;направлениям',
                    en: 'Contacts by&nbsp;directions'
                }),
                contactsMedia: localize({
                    ru: 'Контакты для прессы',
                    en: 'News and media contacts'
                }),
                hotline: localize({
                    ru: 'Служба корпоративного доверия',
                    en: 'Corporate Trust Line'
                }),
                contactsSuppliers: localize({
                    ru: 'Контакты для поставщиков',
                    en: 'Suppliers contacts'
                }),
                contactsInvestors: localize({
                    ru: 'Контакты для инвесторов',
                    en: 'Investors contacts'
                }),
                contactsCareers: localize({
                    ru: 'Работа и&nbsp;карьера',
                    en: 'Careers contacts'
                }),
                contactsNonCoreAssets: localize({
                    ru: 'Контакты по&nbsp;вопросам продажи непрофильного имущества',
                    en: 'Non-core assets contacts'
                }),
                contactsEsg: localize({
                    ru: 'Контакты ESG',
                    en: 'ESG contacts'
                }),
                contactsInnovation: localize({
                    ru: 'Контакты по&nbsp;вопросам инноваций',
                    en: 'Innovations contacts'
                }),
                contactsSales: localize({
                    ru: 'Контакты сбытовых офисов',
                    en: 'Sales network'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.contacts-page-vue {
    &__wrapper {
        border-bottom: 1px solid $token-colors-gray-20;
    }

    .z-caption {
        margin-top: 0 !important;
    }

    .z-accordion--nested {
        margin-top: 0;
    }

    &__label {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.2;
        color: $token-colors-gray-50;

        @include margin-level(bottom, 2XS);
    }

    &__text {
        font-weight: 400;
        font-size: 20px;
        line-height: 1.4;
        color: $token-colors-gray-60;

        @include margin-level(bottom, 3XS);

        &--fade {
            color: $token-colors-gray-30;
            margin-bottom: 0;
        }
    }

    .z-link {
        display: flex;
        align-items: flex-start;
        font-weight: 600;
    }
}
</style>
