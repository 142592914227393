<template>
    <div class="business-model" ref="businessModel">
        <div class="container">
            <contribution />
            <resources-result class="u-bottom-margin--xl"/>
            <esg />
        </div>
        <logistics-and-sales />
    </div>
</template>

<script>
import { usePageAnimation } from '@/utils/pageAnimation'
import Contribution from './components/Contribution.vue'
import ResourcesResult from './components/ResourcesResult.vue'
import Esg from './components/Esg.vue'
import LogisticsAndSales from './components/LogisticsAndSales.vue'

export default {
    name: 'business-model',
    components: { Contribution, ResourcesResult, Esg, LogisticsAndSales },
    mounted () {
        this.$nextTick(() => {
            usePageAnimation(this.$refs.businessModel)
        })
    }
}
</script>

<style lang="scss">
.business-model {
    .left-col{
        margin-right: 90px;
        flex-basis: 28.293%;
        max-width: 28.293%;

        @include breakpoint(laptop) {
            margin-right: 64px;
            flex-basis: 28.74%;
            max-width: 28.74%;
        }

        @include breakpoint(tablet) {
            margin-right: 50px;
            flex-basis: calc(50% - 25px);
            max-width: calc(50% - 25px);
        }

        @include breakpoint(v-tablet) {
            margin: 0 0 56px;
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    .right-col {
        flex-basis: calc(71.707% - 90px);
        max-width: calc(71.707% - 90px);

        @include breakpoint(laptop) {
            flex-basis: calc(71.26% - 64px);
            max-width: calc(71.26% - 64px);
        }

        @include breakpoint(tablet) {
            flex-basis: calc(50% - 25px);
            max-width: calc(50% - 25px);
        }

        @include breakpoint(v-tablet) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    .esg {
        .left-col {
            margin-right: 0;
            margin-left: 90px;

            @include breakpoint(laptop) {
                margin-left: 64px;
            }

            @include breakpoint(tablet) {
                margin: $token-spacers-xl 0 0;
            }
        }

        @include breakpoint(tablet) {
            .left-col, .right-col {
                max-width: 100%;
                flex-basis: 100%;
            }
        }
    }

    & .z-card {
        border-radius: 20px;
    }
}

</style>
