<template>
  <div v-if="data && data.length">
    <z-filelist class="files-list">
      <div class="row">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="col-default-12"
        >
          <z-filelist-item
            :href="item.file.link"
            :name="item.title"
            :icon-name="item.file.type === `vnd.ms-excel` ? `xls` : 'file/' + item.file.type"
            :file-size="item.file.size"
          >
          </z-filelist-item>
        </div>
      </div>
    </z-filelist>
  </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        }
    }
}
</script>
