/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'round-arrow-folder/line-m': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M39 20c0 10.493-8.507 19-19 19S1 30.493 1 20 9.507 1 20 1s19 8.507 19 19zm1 0c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20zm-20.586-5.815a.498.498 0 01.712.004l5.685 5.686a.5.5 0 11-.707.707l-4.837-4.837V25.92a.5.5 0 11-1 0V15.745l-4.837 4.837a.5.5 0 01-.707-.707l5.69-5.69z" _fill="#0077C8"/>'
  }
})
