<template>
    <div class="modern-slider">
        <div class="swiper-container modern-slider__container" ref="slider">
            <div class="swiper-wrapper">
                <slot></slot>
            </div>
        </div>

        <div
            :class="['modern-slider__nav-container', navPositionClass]"
            v-if="showNavigation"
        >
            <div
                class="modern-slider__nav-wrapper modern-slider__nav-wrapper--prev"
                ref="prev"
            >
                <button class="modern-slider__nav-btn modern-slider__nav-btn--prev" @click="handlerTrackGoal(goalName)"></button>
                <z-icon
                    name="round-arrow-folder/arrow"
                    dir="left"
                    width="24px"
                    height="24px"
                    class="modern-slider__nav-icon"
                />
            </div>

            <div class="modern-slider__nav-circle">
                <circle-timer
                    v-if="startTimer"
                    :duration="autoplayDelay"
                    :key="`${timerId}-${activeIndex}`"
                    ref="timer"
                />

                <div class="modern-slider__fraction" v-if="countSlides">
                    <span class="modern-slider__fraction-active">
                        {{ getFormattedNumber(activeSlide) }}
                    </span>
                    /
                    <span class="modern-slider__fraction-count">
                        {{ getFormattedNumber(countSlides) }}
                    </span>
                </div>
            </div>

            <div
                class="modern-slider__nav-wrapper modern-slider__nav-wrapper--next"
                ref="next"
            >
                <button class="modern-slider__nav-btn modern-slider__nav-btn--next" @click="handlerTrackGoal(goalName)"></button>
                <z-icon
                    name="round-arrow-folder/arrow"
                    dir="right"
                    width="24px"
                    height="24px"
                    class="modern-slider__nav-icon"
                />
            </div>
        </div>

        <div class="modern-slider__thumbs-container" v-if="thumbsData">
            <modern-thumbs
                :data="thumbsData"
                :title="thumbsTitle"
                :default-tag="thumbsDefaultTag"
                :active-index="activeIndex"
                @change="setActiveSlide"
                :goal-name-thumb="goalNameThumb"
            />
        </div>
    </div>
</template>

<script>
import gsap from 'gsap'
import { mixinDevice } from '@/utils/mixin'
import { trackGoal } from '@/utils/analytics'
import Swiper from 'swiper/dist/js/swiper.js'
import CircleTimer from './components/CircleTimer'
import ModernThumbs from './components/ModernThumbs.vue'

export default {
    name: 'modern-slider',
    components: { CircleTimer, ModernThumbs },
    mixins: [mixinDevice],
    props: {
        countSlides: {
            type: Number,
            required: true
        },
        thumbsData: Array,
        thumbsTitle: String,
        thumbsDefaultTag: {
            type: String,
            default: ''
        },
        showNavigation: {
            type: Boolean,
            default: true
        },
        navPosition: {
            type: String,
            default: 'right',
            validator: prop => ['left', 'right'].includes(prop)
        },
        autoplayDelay: {
            type: Number,
            default: 6800
        },
        swiperOptions: {
            type: Object
        },
        goalName: {
            type: String
        },
        goalNameThumb: {
            type: String
        }
    },
    data () {
        return {
            slider: null,
            sliderSpeed: 800,
            activeIndex: 0,
            timerId: null,
            startTimer: false,
            prevBtn: null,
            nextBtn: null,
            floatContainer: null,
            floatTrigger: null,
            floatBtn: null,
            isFloating: false,
            floatBtnDir: 'right'
        }
    },
    computed: {
        activeSlide () {
            return this.activeIndex + 1
        },
        navPositionClass () {
            return `modern-slider__nav-container--${this.navPosition}`
        },
        floatContainerWidth () {
            if (!this.floatContainer) return null
            return this.floatContainer.getBoundingClientRect().width
        }
    },
    methods: {
        init () {
            const options = {
                observer: true,
                observeParents: true,
                autoHeight: false,
                loop: true,
                speed: this.sliderSpeed,
                autoplay: {
                    delay: this.autoplayDelay,
                    disableOnInteraction: false,
                    waitForTransition: false
                },
                navigation: {
                    prevEl: this.prevBtn,
                    nextEl: this.nextBtn
                },
                slidesPerView: 1,
                on: {
                    autoplayStart: () => {
                        if (!this.startTimer) this.startTimer = true
                        this.timerId = Date.now()
                    },
                    slideChange: () => {
                        this.activeIndex = this.slider.realIndex
                    },
                    resize: () => {
                        this.timerId = Date.now()
                    },
                    touchEnd: () => {
                        this.timerId = Date.now()
                    }
                }
            }

            this.slider = new Swiper(this.$refs.slider, Object.assign({}, this.swiperOptions, options))
        },
        setActiveSlide (orderNum) {
            this.slider.slideTo(orderNum, this.sliderSpeed)
        },
        getFormattedNumber (number) {
            return number < 10 ? `0${number}` : number
        },
        restartAutoplay () {
            if (document.visibilityState === 'visible') {
                this.slider.autoplay.stop()
                this.slider.autoplay.start()
            }
        },
        changeSlide () {
            setTimeout(() => {
                if (this.isFloating) return

                this.floatBtnDir === 'right'
                    ? this.slider.slideNext(this.sliderSpeed)
                    : this.slider.slidePrev(this.sliderSpeed)
            }, 100)
        },
        floatBtnSlideRight () {
            const tl = gsap.timeline({
                defaults: { ease: 'none' },
                onStart: () => {
                    this.floatBtnDir = 'right'
                    this.isFloating = true
                },
                onComplete: () => { this.isFloating = false }
            })

            tl.to(this.nextBtn, {
                opacity: 0
            }, 0)
                .to(this.prevBtn, {
                    opacity: 1
                }, 0)
                .to(this.floatBtn, {
                    duration: 0.3,
                    translateX: '0px',
                    rotate: '0deg'
                }, 0)
        },
        handlerTrackGoal (goalName) {
            trackGoal(goalName)
        }
    },
    mounted () {
        this.prevBtn = this.$refs.prev
        this.nextBtn = this.$refs.next
        this.floatBtn = this.$refs.floatBtn
        this.floatContainer = this.$refs.floatContainer
        this.floatTrigger = this.$refs.floatTrigger

        this.init()
        document.addEventListener('visibilitychange', this.restartAutoplay)
    },
    destroyed () {
        document.removeEventListener('visibilitychange', this.restartAutoplay)
    }
}
</script>

<style lang="scss">
.modern-slider {
    $parent: &;
    width: 100%;
    position: relative;

    .swiper-container {
        display: flex;
    }

    .swiper-wrapper {
        transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }

    &__nav-container {
        display: flex;
        align-items: center;
        position: absolute;
        top: 20%;
        z-index: 2;

        @include breakpoint(v-tablet) {
            gap: 1rem;
            top: auto;
            bottom: 158px;
        }

        @include breakpoint(mobile) {
            gap: 2rem;
            bottom: 208px;
        }

        &--left {
            left: 2.125rem;
        }

        &--right {
            right: 2.125rem;
        }
    }

    &__nav-wrapper {
        width: 72px;
        height: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;

        &:hover {
            .modern-slider__nav-btn {
                opacity: 0.9;
            }

            .modern-slider__nav-icon {
                path {
                    fill: #0077c8;
                }
            }
        }

        @include breakpoint(v-tablet) {
            width: 40px;
            height: 40px;
            opacity: 1 !important;
        }

        &--prev {
            margin-right: -20px;

            @include breakpoint(v-tablet) {
                margin-right: 0;
            }
        }

        &--next {
            margin-left: -20px;
            z-index: 2;

            @include breakpoint(v-tablet) {
                margin-left: 0;
            }
        }
    }

    &__nav-trigger {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        position: relative;
        z-index: 4;
        cursor: pointer;
    }

    &__nav-btn {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border: none;
        border-radius: 50%;
        padding: 0;
        transition: opacity $transition;
        opacity: 0.4;

        @include breakpoint(v-tablet) {
            opacity: 0.9;
            pointer-events: all;
        }
    }

    &__nav-icon {
        position: absolute;
        top: calc(50% - 12px);
        left: calc(50% - 12px);
        pointer-events: none;

        path {
            fill: $token-colors-white;
            transition: fill $transition;

            @include breakpoint(v-tablet) {
                fill: $token-colors-blue;
            }
        }
    }

    &__nav-circle {
        width: 140px;
        height: 140px;
        position: relative;
        z-index: 2;

        @include breakpoint(v-tablet) {
            transform: scale(0.5);
            margin: -35px;
        }

        @include breakpoint(mobile) {
            position: absolute;
            visibility: hidden;
            pointer-events: none;
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: $token-colors-white;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.3;
        }
    }

    &__nav-progress {
        position: relative;
        z-index: 1;
    }

    &__fraction {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Roboto Mono";
        font-size: 24px;
        font-weight: 300;
        color: #ffffff;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;

        @include breakpoint(v-tablet) {
            font-weight: 600;
        }
    }

    &__fraction-active {
        font-size: 28px;
    }

    &__thumbs-container {
        width: 100%;
        position: absolute;
        bottom: 1.75rem;
        left: 0;
        z-index: 1;

        @include breakpoint(v-tablet) {
            bottom: 1rem;
        }
    }
}
</style>
