<template>
    <section class="register-dishonest">
        <card-link class="u-bottom-margin--m" link="/suppliers/warning/" theme="warning" image="/images/spy.svg">
            Предупреждение о&nbsp;подложных обращениях от&nbsp;лица компании
        </card-link>
        <z-caption tag="h2">Список контрагентов*</z-caption>
        <z-input class="u-bottom-margin--s u-top-margin--s" v-model="search" name="search"
                 placeholder="Введите Наименование или ИНН компании" icon-name="magnifier"/>
        <div class="u-bottom-margin--s register-dishonest__content">
                <z-table ref="tableComp" v-if="data && data.length" :columns="['Наименование', 'ИНН', 'Основание', 'Дата']" responsive>
                    <template v-for="(item, index) in data">
                        <z-table-row :is-hidden="index > 14" :key="`table-item-${index}`">
                            <z-table-col index="0">{{ item.name }}</z-table-col>
                            <z-table-col index="1">{{ item.inn }}</z-table-col>
                            <z-table-col index="2">
                                <z-tooltip :content="getReasonTooltipText(item.reason)">
                                    <span>{{ getReasonLabelText(item.reason) }}</span>
                                </z-tooltip>
                            </z-table-col>
                            <z-table-col index="3">{{ item.date }}</z-table-col>
                        </z-table-row>
                    </template>
                </z-table>
                <z-not-found v-else/>
        </div>
        <z-accordion v-if="defaultRegisterDishonestReasonsData && defaultRegisterDishonestReasonsData.length" class="u-bottom-margin--s">
            <z-accordion-item id="1">
                <template #header>
                    Основания включения в&nbsp;реестр:
                </template>
                <template #body>
                    <z-list tag="ol">
                        <z-list-item v-for="{ id, detail } in defaultRegisterDishonestReasonsData" :key="id">
                            <vue-raw :raw="detail" />
                        </z-list-item>
                    </z-list>
                </template>
            </z-accordion-item>
        </z-accordion>
        <z-card theme="grey">
            <template #content>
                * Информация, содержащаяся в&nbsp;реестре, не&nbsp;используется при принятии решений о&nbsp;заключении
                договоров, заключаемых по&nbsp;результатам закупочных процедур, проводимых в&nbsp;соответствии с&nbsp;Федеральным
                законом &laquo;О&nbsp;закупках товаров, работ, услуг отдельными видами юридических лиц&raquo; от&nbsp;18.07.2011
                &#8470;&nbsp;223-ФЗ, и&nbsp;доходных договоров, заключение которых для ПАО &laquo;ГМК &laquo;Норильский
                никель&raquo; и&nbsp;организаций, входящих в&nbsp;его группу лиц, является обязательным в&nbsp;соответствии
                с&nbsp;требованиями действующего законодательства&nbsp;РФ.
            </template>
        </z-card>
    </section>
</template>

<script>
import { getRegisterDishonest } from '@/api/register-dishonest'

import {debounce} from 'throttle-debounce'

export default {
    name: 'register-dishonest',
    data () {
        return {
            search: '',
            // eslint-disable-next-line no-undef
            data: []
        }
    },
    watch: {
        search () {
            if (this.search === '') {
                this.data = this.defaultRegisterDishonestData.slice()
                this.$nextTick(() => {
                    if (this.$refs.tableComp) this.$refs.tableComp.updateHiddenRows()
                })
                return
            }
            this.searchItems()
        }
    },
    methods: {
        searchItems: debounce(500, function () {
            const that = this

            getRegisterDishonest({ q: this.search })
                .then(function (data) {
                    that.data = data && data.items ? data.items : []

                    that.$nextTick(() => {
                        if (that.$refs.tableComp) that.$refs.tableComp.updateHiddenRows()
                    })
                })
                .catch(function (e) {
                    console.error(e)
                })
        }),
        getReasonLabelText (reason) {
            return reason.map(e => e.id).join(', ')
        },
        getReasonTooltipText (reason) {
            const that = this
            return reason.map(function (e) {
                const reason = that.defaultRegisterDishonestReasonsData.find(r => r.id === e.id)

                return `<div class="register-dishonest__tooltip-text">${reason ? reason.preview : ''}</div>`
            }).join('<br><br>')
        }
    },
    computed: {
        defaultRegisterDishonestData () {
            // eslint-disable-next-line no-undef
            return App.components['register-dishonest'].items ? App.components['register-dishonest'].items : []
        },
        defaultRegisterDishonestReasonsData () {
            // eslint-disable-next-line no-undef
            if (App.components['register-dishonest-reasons'].items) {
                // eslint-disable-next-line no-undef
                return App.components['register-dishonest-reasons'].items
                    .sort((a, b) => a.id - b.id)
            } else {
                return []
            }
        }
    },
    created () {
        this.data = this.defaultRegisterDishonestData.slice()
    }
}
</script>

<style lang="scss">
.register-dishonest {
    ul li {
        margin-bottom: $token-spacers-3-xs;
    }

    li ul {
        @include padding-level(left, M);
    }

    &__content {
        display: flex;
        justify-content: center;
    }

    &__tooltip {
        &-text {
            margin-bottom: 0 !important;

            & > * {
                display: inline !important;
            }
        }
    }

    .z-table-col:first-child {
        width: 50%;
    }

    .z-input.is-focused .z-input__placeholder {
        transform: translateY(-3.1em);
    }
    .z-input.is-valid .z-input__placeholder {
        transform: translateY(-3.1em);
    }
}
</style>
