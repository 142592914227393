<template>
    <div class="become-supplier-form">
        <button
            type="button"
            class="become-supplier-form__form-button"
            @click="onClick"
        ></button>

        <z-modal class="become-supplier-form__modal" id="slotModal">
            <z-caption tag="h3" size="2xl" icon="message">
                {{ text.title }}
            </z-caption>
            <form @submit.prevent="submitForm">
                <div class="row">
                    <div class="col-default-6 col-tablet-12">
                        <z-select
                            v-model="formData.supContactsCat"
                            required
                            :data="category"
                            type="text"
                            name="supContactsCat"
                            ref="supContactsCat"
                            :title="text.category"
                            class="contacts-form__input"
                        ></z-select>

                        <z-input
                            v-if="formData.supContactsCat === 'sup-cat-1'"
                            v-model="formData.supContactsProcedure"
                            type="text"
                            ref="purchaseCategory"
                            name="purchaseCategory"
                            :title="text.purchaseCategory"
                            class="contacts-form__input"
                        ></z-input>

                        <z-input
                            v-if="formData.supContactsCat === 'sup-cat-2'"
                            v-model="formData.procurementProcedure"
                            type="text"
                            ref="procurementProcedure"
                            name="procurementProcedure"
                            :title="text.procurementProcedure"
                            class="contacts-form__input"
                        ></z-input>

                        <z-input
                            v-model="formData.fio"
                            type="text"
                            ref="fio"
                            name="fio"
                            :title="text.fio"
                            class="contacts-form__input"
                        ></z-input>

                        <z-input
                            v-model="formData.company"
                            type="text"
                            ref="company"
                            required
                            name="company"
                            :title="text.company"
                            class="contacts-form__input"
                        ></z-input>

                        <z-input
                            v-model="formData.email"
                            type="email"
                            ref="email"
                            name="email"
                            :title="text.email"
                            class="contacts-form__input"
                        ></z-input>

                        <div class="row">
                            <div class="col-default-8 col-tablet-12">
                                <z-input
                                    v-model="formData.phone"
                                    ref="phone"
                                    type="tel"
                                    name="phone"
                                    :title="text.phone"
                                ></z-input>
                            </div>
                            <div class="col-default-4 col-tablet-12">
                                <z-input
                                    v-model="formData.supContactsPhoneAdd"
                                    ref="phoneAdd"
                                    type="number"
                                    name="phoneAdd"
                                    :title="text.phoneAdd"
                                ></z-input>
                            </div>
                        </div>

                        <p class="contacts-form__text">{{ text.notificationCaptchaEmpty }}</p>
                        <div class="form-captcha">
                            <div class="form-captcha__wrapper">
                                <div class="form-captcha__captcha">
                                    <img
                                        class="form-captcha__img"
                                        v-if="isUpdatedCapcha"
                                        :src="'/api/captcha/?' + capchaSalt"
                                        width="100"
                                        height="50"
                                        alt=""
                                    />
                                    <div v-else class="form-captcha__img"></div>

                                    <z-button
                                        class="form-captcha__reset-btn"
                                        type="button"
                                        rounded
                                        kind="secondary"
                                        @click="updateCapcha"
                                    >
                                        <z-icon name="refresh" width="24" height="24"></z-icon>
                                    </z-button>
                                </div>

                                <z-input
                                    v-model="captcha"
                                    type="text"
                                    name="captcha"
                                    ref="captcha"
                                    required
                                    class="form-captcha__input"
                                ></z-input>
                            </div>
                        </div>
                    </div>

                    <div class="col-default-6 col-tablet-12">
                        <z-caption class="contacts-form__select-title" tag="p" size="s">
                            {{ text.message }}
                        </z-caption>
                        <z-textarea
                            v-model="formData.msg"
                            required
                            name="msg"
                            ref="msg"
                            class="contacts-form__input"
                        ></z-textarea>

                        <div class="contacts-form__captcha-container">
                            <z-checkbox
                                name="policy"
                                ref="policy"
                                v-model="isChecked"
                                class="contacts-form__checkbox"
                                required
                                :data="[{ value: '1' }]"
                            >
                                <template #text>
                                    <z-caption tag="p" weight="400" size="xs">
                                        {{ text.policy }}
                                    </z-caption>
                                </template>
                            </z-checkbox>
                        </div>

                        <z-button>
                            {{ text.button }}
                        </z-button>
                    </div>
                </div>
            </form>
        </z-modal>
    </div>
</template>

<script>
import { showNotyfications } from '@/utils/notifications'
import { sendBecomeSupplierForm } from '@/api/become-supplier-form.js'
import { sendCaptcha } from '@/api/captcha.js'
import { text, category } from './constants'

export default {
    name: 'become-supplier-form',
    data () {
        return {
            isChecked: [],
            isLoading: false,
            formData: {
                form: 'suppliers-contacts',
                category: '',
                supContactsProcedure: '',
                supContactsCat: '',
                procurementProcedure: '',
                fio: '',
                company: '',
                phone: '',
                supContactsPhoneAdd: '',
                email: '',
                msg: '',
                ajax_call: 'Y'
            },
            text,
            category,
            capchaSalt: Date.now(),
            captcha: '',
            captchaImg: null,
            isUpdatedCapcha: true
        }
    },
    computed: {
        getSelectText () {
            let obj = this.category.find((item) => {
                return this.formData.supContactsCat === item.id
            })

            return obj?.text
        },
        createProcedureString () {
            if (this.formData.supContactsCat === 'sup-cat-1' || this.formData.supContactsCat === 'sup-cat-2') {
                return `<strong style="color: #000;">${this.text.purchaseCategory}</strong>:<br /> ${this.formData.supContactsProcedure}`
            }

            return ''
        }
    },
    methods: {
        onClick () {
            this.$root.$bus.$emit('open-modal', {id: 'slotModal'})
            // eslint-disable-next-line no-undef
            if (typeof yaCounter45303309 !== 'undefined') {
                // eslint-disable-next-line no-undef
                yaCounter45303309.reachGoal('futuresupplierscontacts')
            }
        },
        validateInputFormat () {
            if (this.formData.phone || this.formData.email) {
                const requiredFields = ['phone']
                let isValid = true

                requiredFields.forEach((item) => {
                    const field = this.$refs[item]
                    if (field) {
                        field.validate()
                        if (field.error) isValid = false
                    }
                })
                return isValid
            }

            return true
        },
        validateRequiredInputs () {
            const requiredFields = ['category', 'company', 'policy', 'captcha', 'msg']
            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },
        updateCapcha () {
            this.isUpdatedCapcha = false
            this.captcha = ''
            this.capchaSalt = Date.now()
            setTimeout(() => {
                this.isUpdatedCapcha = true
            }, 500)
        },

        async submitForm () {
            if (this.isLoading) {
                return
            }

            const isValidInputFormat = this.validateInputFormat()

            if (!isValidInputFormat) {
                showNotyfications(this.text.notificationValidateError, { type: 'error' })
                return
            }

            const isValidRequiredInputs = this.validateRequiredInputs()

            if (isValidRequiredInputs) {
                try {
                    if (typeof yaCounter45303309 !== 'undefined') {
                        // eslint-disable-next-line no-undef
                        yaCounter45303309.reachGoal('futuresupplierssent')
                    }

                    this.isLoading = true

                    if (this.captcha === '') {
                        showNotyfications(this.text.notificationCaptchaEmpty, { type: 'error' })
                        return
                    }

                    const data = new FormData()

                    this.formData.category = this.getSelectText
                    this.formData.procedure = this.createProcedureString

                    for (const key in this.formData) {
                        data.append(key, this.formData[key])
                    }

                    const captcha = {
                        phrase: this.captcha
                    }

                    const response = await sendCaptcha(captcha)

                    if (response.status === false) {
                        showNotyfications(this.text.notificationCaptchaError, { type: 'error' })
                    } else {
                        const formResponse = await sendBecomeSupplierForm(data)

                        if (formResponse.success === true) {
                            showNotyfications(this.text.notificationSuccess, { type: 'success' })
                            this.formData.category = ''
                            this.formData.supContactsCat = ''
                            this.formData.purchaseCategory = ''
                            this.formData.procurementProcedure = ''
                            this.formData.fio = ''
                            this.formData.company = ''
                            this.formData.email = ''
                            this.formData.phone = ''
                            this.formData.supContactsPhoneAdd = ''
                            this.captcha = ''
                            this.formData.msg = ''
                            this.isChecked = []
                        } else {
                            showNotyfications(this.text.notificationError, { type: 'error' })
                        }
                    }
                    this.updateCapcha()
                } catch (error) {
                    console.log(error)
                    showNotyfications(this.text.notificationError, { type: 'error' })
                } finally {
                    this.isLoading = false
                }
            } else {
                showNotyfications(this.text.notificationRequiredTextError, { type: 'error' })
            }
        }
    }
}
</script>

<style lang="scss">
.become-supplier-form {
    &__form-button {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        background-color: transparent;
        background-image: url('/images/contacts/round_mail.svg');
        background-repeat: no-repeat;
        border: none;
        outline: none;
        position: fixed;
        bottom: 5%;
        right: 5%;
        box-shadow: 0px 4px 4px rgba(0, 32, 51, 0.04), 0px 8px 24px rgba(0, 32, 51, 0.12);
        z-index: 1;

        &:hover {
            background-image: url('/images/contacts/round_mail_hover.svg');
        }
    }

    &__form-title {
        margin-left: 12px;
        margin-right: 36px;

        @media screen and(min-width: 1679px) {
            margin-left: 50px;
        }

        @media screen and(max-width: 1023px) {
            margin-left: 2px;
        }

        @media screen and(max-width: 598px) {
            margin-left: 7px;
            font-size: 18px;
        }
    }
}

.contacts-form-wrapper {
    &__form-button {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        background-color: transparent;
        background-image: url('/images/contacts/round_mail.svg');
        background-repeat: no-repeat;
        border: none;
        outline: none;
        position: fixed;
        bottom: 5%;
        right: 5%;
        box-shadow: 0px 4px 4px rgba(0, 32, 51, 0.04), 0px 8px 24px rgba(0, 32, 51, 0.12);

        &:hover {
            background-image: url('/images/contacts/round_mail_hover.svg');
        }
    }

    &__form-title {
        margin-left: 12px;
        margin-right: 36px;

        @media screen and(min-width: 1679px) {
            margin-left: 50px;
        }

        @media screen and(max-width: 1023px) {
            margin-left: 2px;
        }

        @media screen and(max-width: 598px) {
            margin-left: 7px;
            font-size: 18px;
        }
    }

    &__form {
        max-width: 880px;
    }
}
</style>
