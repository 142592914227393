<template>
    <section class="contribution">
        <z-caption
            :decor-left="true"
            tag="p"
            size="m"
            class="u-bottom-margin--m-important"

        >
            <span data-scroll-heading v-html="text.unGoals"></span>
        </z-caption>

        <div class="contribution__content">
            <div class="left-col">
                <div class="contribution__icons" data-scroll-translate>
                    <img 
                        :src="lang === 'ru' ? '/local/templates/z-template/img/business-model/3.svg' : '/images/sustainability/un-goals/en/3.png'" alt=""
                    >
                    <img 
                        :src="lang === 'ru' ? '/local/templates/z-template/img/business-model/8.svg' : '/images/sustainability/un-goals/en/8.png'" alt=""
                    >
                    <img 
                        :src="lang === 'ru' ? '/local/templates/z-template/img/business-model/9.svg' : '/images/sustainability/un-goals/en/9.png'" alt=""
                    >
                    <img 
                        :src="lang === 'ru' ? '/local/templates/z-template/img/business-model/11.svg' : '/images/sustainability/un-goals/en/11.png'" alt=""
                    >
                    <img 
                        :src="lang === 'ru' ? '/local/templates/z-template/img/business-model/12.svg' : '/images/sustainability/un-goals/en/12.png'" alt=""
                    >
                    <img 
                        :src="lang === 'ru' ? '/local/templates/z-template/img/business-model/13.svg' : '/images/sustainability/un-goals/en/13.png'" alt=""
                    >
                </div>
            </div>
            <div class="right-col">
                <div class="contribution__facts" data-scroll-translate>
                    <div class="contribution__facts-item">
                        <div class="contribution__facts-icon">
                            <z-icon name="efficiency-big" />
                        </div>
                        <p class="contribution__facts-text" v-html="text.factFirst">                            
                        </p>
                    </div>
                    <div class="contribution__facts-item">
                        <div class="contribution__facts-icon">
                            <z-icon name="esg-big" />
                        </div>
                        <p class="contribution__facts-text" v-html="text.factSecond">
                        </p>
                    </div>
                    <div class="contribution__facts-item">
                        <div class="contribution__facts-icon">
                            <z-icon name="goal-big" />
                        </div>
                        <p class="contribution__facts-text" v-html="text.factThird">                            
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
  data() {
    return {
      lang: this.$root.lang,
      text: {
        unGoals: localize({
          ru: 'Вклад в&nbsp;достижения ЦУР ООН',
          en: 'Contribution to&nbsp;the UN&nbsp;SDGs'
        }),
        factFirst: localize({
          ru: 'Рост объемов производства основных металлов Компании на&nbsp;20&ndash;25% к&nbsp;2030 году',
          en: 'A&nbsp;20–25% growth in&nbsp;the output of&nbsp;the Company’s core metals by&nbsp;2030'
        }),
        factSecond: localize({
          ru: 'Снижение негативного экологического воздействия в&nbsp;регионах присутствия',
          en: 'Reduced environmental footprint across regions of&nbsp;operation'
        }),
        factThird: localize({
          ru: 'Более глубокая интеграция в&nbsp;формирующиеся цепочки создания стоимости и&nbsp;диверсификация производственных мощностей',
          en: 'Deeper integration into emerging value chains and diversification of&nbsp;production capacities'
        }),
      }
    }
  },
}
</script>

<style lang="scss">
.contribution {
    @include margin-level(bottom, 2XL);

    &__content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include breakpoint(laptop) {
            align-items: flex-start;
        }
    }

    &__icons {
        max-width: 407px;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        @include breakpoint(v-tablet) {
            margin: 0 auto;
            justify-content: center;
        }

        & img {
            max-width: 106px;
        }
    }

    &__facts {
        height: fit-content;
        display: flex;
        align-items: start;
        gap: 64px;

        @include breakpoint(laptop) {
            flex-direction: column;
            gap: 24px;
        }

        &-item {
            flex: 1 1;
            display: flex;
            gap: 24px;

            & .z-icon {
                width: 83px;
                height: 83px;

                @include breakpoint(v-tablet) {
                    width: 69px;
                    height: 69px;
                }
            }
        }

        &-text {
            max-width: 285px;
            @include typo-level(S);

            @include breakpoint(laptop) {
                max-width: 627px;
            }
        }
    }
}

</style>
