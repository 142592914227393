export const filesAdapter = (files) => files.map((file) => ({
    ...file,
    title: file.name,
    group: {
        title: file.group.value,
        id: file.group['xml-id'],
        slug: file.group['xml-id'],
        'xml-id': file.group['xml-id']
    }
}))
