<template>
  <div class="files">
    <accordion-template :data="groupData" />

    <file-list
      class="u-top-margin--xl"
      :data="fileList"
    />
  </div>
</template>

<script>
import FileList from './components/FileList.vue'
import AccordionTemplate from './templates/AccordionTemplate.vue'

export default {
    components: { FileList, AccordionTemplate },
    props: {
        size: {
            type: String,
            validator: prop => ['l', 'xl'].includes(prop),
            default: 'xl'
        },
        showDate: {
            type: Boolean,
            default: false
        },
        data: {
            type: Array,
            default: () => []
        },
        sortingByName: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        fileList () {
            if (!this.data) { return [] }

            return this.data.filter(item => !(item.group && item.group.id && item.group.title))
        },
        groupData () {
            if (!this.data) { return [] }

            const result = this.data.reduce(function (r, a) {
                if (!(a.group && a.group.id && a.group.title)) { return r }

                r[a.group.slug] = r[a.group.slug] || {}

                r[a.group.slug].name = r[a.group.slug].name || a.group.title
                r[a.group.slug]['xml-id'] = r[a.group.slug]['xml-id'] || a.group.slug
                r[a.group.slug].items = r[a.group.slug].items || []
                r[a.group.slug].items.push(a)
                return r
            }, Object.create(null))

            return this.sortingByName
                ? Object.values(result).sort((a, b) => b.name - a.name)
                : Object.values(result)
        }
    }
}
</script>
