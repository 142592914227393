/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Icon/map-circl-block': {
    width: 118,
    height: 146,
    viewBox: '0 0 118 146',
    data: '<path pid="0" d="M0 20C0 8.954 8.954 0 20 0h78c11.046 0 20 8.954 20 20v106c0 11.046-8.954 20-20 20H0V20z" _fill="#0077C8"/><path pid="1" d="M45.394 75.486l-15.437 2.355-14.914 31.66 23.025-5.233m7.326-28.782l-7.326 28.782m7.326-28.782l14.653 2.355m-21.979 26.427l21.979 5.233m0 0v-31.66m0 31.66l19.362-5.233M60.047 77.841l15.699-2.355m3.663 28.782l-3.663-28.782m3.663 28.782l23.548 5.233-12.036-31.66-15.175-2.355" _stroke="url(#paint0_linear_10351_2138)" stroke-width="1.693"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M60.423 86.135L75.29 71.267a21.025 21.025 0 10-29.734 0l14.867 14.868z" _fill="#0077C8"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M45.556 71.267a21.026 21.026 0 1129.734 0L60.423 86.135 45.556 71.267zm14.867 12.475l13.67-13.67a19.333 19.333 0 10-27.341 0l13.67 13.67z" _fill="#fff"/><circle pid="4" cx="60.423" cy="56.515" r="8.25" _stroke="#fff" stroke-width="1.5"/><defs><linearGradient id="paint0_linear_10351_2138" x1="59" y1="75.486" x2="59" y2="109.501" gradientUnits="userSpaceOnUse"><stop stop-color="#fff" stop-opacity=".1"/><stop offset="1" stop-color="#fff"/></linearGradient></defs>'
  }
})
