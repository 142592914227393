/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'goal-big': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M44 13c-17.124 0-31 13.876-31 31 0 17.124 13.876 31 31 31 17.124 0 31-13.876 31-31 0-5.193-1.28-10.087-3.542-14.392a1 1 0 011.77-.93A32.833 32.833 0 0177 44c0 18.228-14.772 33-33 33-18.229 0-33-14.772-33-33 0-18.229 14.771-33 33-33a32.833 32.833 0 0115.322 3.772 1 1 0 01-.93 1.77A30.832 30.832 0 0044 13zm19.845 5.705a1 1 0 00-1.706-.708l-7.29 7.263a1 1 0 00-.293.709v6.061L43.293 43.293a1 1 0 001.414 1.414L55.97 33.445h6.063a1 1 0 00.708-.294l7.264-7.29a1 1 0 00-.709-1.705h-5.45v-5.45zm-5.876 12.74h3.648l5.27-5.29h-3.629l-5.289 5.29zm3.876-6.704v-3.628l-5.29 5.27v3.647l5.29-5.289zM22.143 44c0-12.045 9.812-21.857 21.857-21.857 2.698 0 5.27.485 7.647 1.383a1 1 0 10.706-1.871A23.58 23.58 0 0044 20.143C30.85 20.143 20.143 30.85 20.143 44S30.85 67.857 44 67.857c13.15 0 23.857-10.708 23.857-23.857 0-2.94-.53-5.752-1.512-8.353a1 1 0 10-1.871.706A21.582 21.582 0 0165.857 44c0 12.044-9.813 21.857-21.857 21.857-12.045 0-21.857-9.813-21.857-21.857zM44 31.286c-7.016 0-12.714 5.698-12.714 12.714S36.984 56.715 44 56.715 56.714 51.016 56.714 44c0-1.888-.4-3.674-1.151-5.293a1 1 0 111.814-.842A14.516 14.516 0 0158.714 44c0 8.121-6.593 14.715-14.714 14.715-8.12 0-14.714-6.594-14.714-14.715 0-8.12 6.593-14.714 14.714-14.714 2.184 0 4.253.492 6.124 1.332a1 1 0 11-.82 1.825A12.91 12.91 0 0044 31.286zm-.894 9.264a1 1 0 10-.497-1.938A5.55 5.55 0 0038.429 44c0 3.066 2.505 5.571 5.571 5.571a5.573 5.573 0 005.388-4.18 1 1 0 00-1.938-.497A3.573 3.573 0 0144 47.571 3.585 3.585 0 0140.43 44a3.55 3.55 0 012.677-3.45z" _fill="#0077C8"/>'
  }
})
