/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'efficiency-big': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M42 35.875v-9h-3c-.4 0-.7-.2-.9-.5-.2-.3-.1-.7.1-1l9-13c.4-.5 1.3-.5 1.6 0l9 13c.2.3.2.7.1 1-.2.3-.5.5-.9.5h-3v18h-2v-18h-4v-2h7.1l-7.1-10.3-7.1 10.3H43c.6 0 1 .4 1 1v10h-2zm-10 22v-9h-3c-.4 0-.7-.2-.9-.5-.2-.3-.1-.7.1-1l9-13c.4-.5 1.3-.5 1.6 0l9 13c.2.3.2.7.1 1-.2.3-.5.5-.9.5h-3v15h-2v-15h-4v-2h7.1l-7.1-10.3-7.1 10.3H33c.6 0 1 .4 1 1v10h-2zm-4 13h4v5h2v-5h3c.4 0 .7-.2.9-.5.1-.3.1-.7-.1-1l-9-13c-.3-.5-1.2-.5-1.6 0l-9 13c-.2.3-.3.7-.1 1 .2.3.5.5.9.5h3v5h2v-6c0-.6-.4-1-1-1h-2.1l7.1-10.3 7.1 10.3H28v2zm30-13h4v18h2v-18h3c.4 0 .7-.2.9-.5.1-.3.1-.7-.1-1l-9-13c-.3-.5-1.2-.5-1.6 0l-9 13c-.2.3-.3.7-.1 1 .2.3.5.5.9.5h3v18h2v-19c0-.6-.4-1-1-1h-2.1l7.1-10.3 7.1 10.3H58v2zm-16 16h2v2h-2v-2zm2-4h-2v2h2v-2zm-2-4h2v2h-2v-2zm34-29H57v2h19v-2zm-64 0h19v2H12v-2zm57-4h-8v2h8v-2zm-12 0h2v2h-2v-2zm-20-4h-2v2h2v-2zm-6 0h2v2h-2v-2z" _fill="#0077C8"/>'
  }
})
